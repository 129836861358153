import { endOfDay, format, formatDistance, formatDistanceToNowStrict, type Locale, parseISO, startOfDay } from 'date-fns';
import { de, enGB } from 'date-fns/locale';

type Locales = {
  'en-GB': Locale;
  'de-DE': Locale;
};

const locales: Locales = {
  'en-GB': enGB,
  'de-DE': de,
};

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
const formatDate = (date: Date | string, formatStr: string = 'PP', locale: string = 'en-GB') => format(typeof date === 'string' ? parseISO(date) : date, formatStr, {
  locale: locales[locale as keyof Locales],
});

const formatDistanceLocalized = (date: Date, baseDate: Date, locale: string = 'en-GB') => formatDistance(date, baseDate, {
  addSuffix: true, // "ago" if date is before baseDate, or "in" prefix if date is after baseDate
  locale: locales[locale as keyof Locales],
});

const formatDistanceToNowStrictLocalized = (date: Date, locale: string = 'en-GB') => formatDistanceToNowStrict(date, {
  addSuffix: true, // "ago" if date is before baseDate, or "in" prefix if date is after baseDate
  locale: locales[locale as keyof Locales],
});

/**
 * Returns a string with two dates, to be used at the backend filter
 * @param date1 start date, that will be at 00:00:00
 * @param date2 end date, that will be at 23:59:59
 * @returns a string with the two dates separated by a comma, e.g. "2023-01-01 00:00:00,2023-12-31 23:59:59"
 * */
const getDatesStrForFilter = (date1: Date, date2: Date) => {
  const formatStr = 'yyyy-MM-dd HH:mm:ss';
  return `${format(startOfDay(date1), formatStr)},${format(endOfDay(date2), formatStr)}`;
};

export {
  formatDate,
  formatDistanceLocalized,
  formatDistanceToNowStrictLocalized,
  getDatesStrForFilter,
};
