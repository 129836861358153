import { useToasted } from '@hoppscotch/vue-toasted';
import type { Toasted, ToastOptions } from 'node_modules/@hoppscotch/vue-toasted/dist';

const showToast = (message: string, options?: ToastOptions) => {
  const toast: Toasted = useToasted();
  toast.show(message, options);
};
const showToastInfo = (message: string) => showToast(message, { type: 'info', icon: 'info-circle' });
const showToastSuccess = (message: string) => showToast(message, { type: 'success', icon: 'check-circle' });
const showToastError = (message: string) => showToast(message, { type: 'error', icon: 'exclamation-circle' });

export function useToast() {
  return {
    showToast,
    showToastInfo,
    showToastSuccess,
    showToastError,
  };
}
