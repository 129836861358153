<template>
  <Transition name="fade-1000">
    <div
      v-if="show"
    >
      <BSpinner
        :class="[`size-${size}`]"
      />
    </div>
  </Transition>
</template>

<script
  setup
  lang="ts"
>
import { ref, watchEffect } from 'vue';
import { useUtils } from '@/composables/useUtils';

const show = ref(false);

const props = defineProps({
  loading: {
    type: Boolean,
    default: true,
  },
  delay: {
    type: Number,
    default: 500,
  },
  size: {
    type: String,
    default: 'default',
  },
});

watchEffect(async () => {
  if (props.loading) {
    await useUtils().wait(props.delay);
    if (props.loading) {
      // Still loading after delay? Show the spinner.
      show.value = true;
    }
  } else {
    // Not loading
    show.value = false;
  }
});
</script>

<style
  lang="scss"
  scoped
>
.spinner {
  color: var(--text-color-1);

  .app-jgo:not(.theme-v3e) & {
    color: white;
  }

  .spinner-border {
    display: block; // instead of inline-block; fixes space below the spinner
    &.size-default {
      $size: 2rem; // Bootstrap default: 2rem
      --bs-spinner-width: #{$size};
      --bs-spinner-height: #{$size};
    }

    &.size-small {
      $size: 1rem;
      --bs-spinner-width: #{$size};
      --bs-spinner-height: #{$size};
    }
  }
}

// Make it disappear instantly
.fade-1000-leave-active {
  transition-duration: 0s;
}
</style>
