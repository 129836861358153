// Pinia Store
import { computed, inject, ref } from 'vue';
import type { Router } from 'vue-router';
import type { AxiosStatic } from 'axios';
// import type { App } from 'vue';
// import type { Router } from 'vue-router';
import { defineStore } from 'pinia';
import { Socket } from 'phoenix';
import sanitizeHtml from 'sanitize-html';
import type { Toasted, ToastObject } from 'node_modules/@hoppscotch/vue-toasted/dist';

// eslint-disable-next-line import/no-cycle
import { useAuthStore } from '@/stores/auth';
// eslint-disable-next-line import/no-cycle
import { useAccountStore } from '@/stores/account';
// eslint-disable-next-line import/no-cycle
// import { useTranslationStore } from '@/stores/translation';
// eslint-disable-next-line import/no-cycle
import { useGlobalStore } from '@/stores/global';
// eslint-disable-next-line import/no-cycle
import { useToastsStore } from '@/stores/toasts';

console.log('Pinia Socket store is being created.'); // no access to Vue.prototype.$log here yet

const appName = import.meta.env.VITE_APP_NAME;

let socketUrl = '';
const localHostnames = [
  'localhost',
  '192.168.41.117',
  '192.168.1.151',
];
if (localHostnames.includes(window.location.hostname)) {
  if (import.meta.env.VITE_APP_LOCAL_SOCKET === 'true') {
    socketUrl = `wss://${window.location.hostname}:4243/socket/local`; // Through local NGINX
  } else {
    socketUrl = `wss://${window.location.hostname}:4243/socket/${appName}`; // wss://localhost|IP:4243/socket/{imp,jgo}
  }
} else {
  socketUrl = `wss://${window.location.host}/socket`;
}
console.log('Socket store: socket URL:', socketUrl);

let router: Router;

let socket: Socket; // here, to be replaced when we need a new connection

const useSocketStore = defineStore('socket', () => {
  // const router = useRouter(); // not working, will be injected via action
  const $log: any = inject('$log');
  const $http: undefined | AxiosStatic = inject('$http');
  const $toasted: Toasted | undefined = inject('$toasted');

  const socketIsConnected = ref(false);
  const channelsJoinStatus = ref<{ [topicId: string]: boolean; }>({});
  const errors = ref<unknown[]>([]);
  const retryTimeout = ref<undefined | number>(undefined);
  const messagingTopicListeners = ref<{ [topicId: string]: Function; }>({});

  const connectedToHelpRequests = computed(() => socketIsConnected.value && 'requests' in channelsJoinStatus.value && channelsJoinStatus.value.requests);
  function printNotImplemented(channelName: string, eventName: string, msg: any) {
    const toBeImplementedMsg = `Channel ${channelName}, event "${eventName}": TO BE IMPLEMENTED. Message:`;
    $log.error(toBeImplementedMsg, msg);
    if (window.ENV && window.ENV.ENVIRONMENT === 'DEV') {
      if (!$toasted) {
        $log.warn('showToast: no $toasted?');
      } else {
        $toasted.show(`(DEV) ${toBeImplementedMsg} ${JSON.stringify(msg)}`);
      }
    }
  }

  function resetState() {
    if (socket) {
      socket.disconnect(() => {
        $log.info('Socket disconnected');
      }, undefined, 'Signed out');
    }

    socketIsConnected.value = false;
    channelsJoinStatus.value = {};
    errors.value = [];
    retryTimeout.value = undefined;
    messagingTopicListeners.value = {};
  }

  // function injectApp(appParam: App) {
  //   app = appParam;
  //   $log.debug('socket: injectApp OK');
  // }

  function injectRouter(routerParam: Router) {
    router = routerParam;
  }

  function joinedChannel(channelName: string) {
    // do not save the ID (split by ':')
    channelsJoinStatus.value[channelName.split(':')[0]] = true;
  }

  function leftChannel(channelName: string) {
    // do not save the ID (split by ':')
    channelsJoinStatus.value[channelName.split(':')[0]] = false;
  }

  function joinChannelRequests() {
    if (!socket) {
      $log.info('Socket store: joinChannelRequests: no socket');
      return;
    }

    const accountStore = useAccountStore();
    const userId = accountStore.userProfile?.id;
    const channelName = `requests:${userId}`;
    $log.info('Socket store: joinChannelRequests: channel name:', channelName);
    const roomToken = '';
    const channel = socket.channel(channelName, { token: roomToken });

    // $input.onEnter(e => {
    //   // Send message
    //   channel.push('new_msg', { body: e.target.val }, 10000)
    //     .receive('ok', msg => console.log(channelName, 'created message', msg))
    //     .receive('error', reasons => console.log(channelName, 'create failed', reasons))
    //     .receive('timeout', () => console.log(channelName, 'Networking issue...'));
    // });

    type MsgRequestJoinResponse = {
      active_requests: {
        help: number;
      };
    };
    channel.join()
      .receive('ok', (response: MsgRequestJoinResponse) => {
        joinedChannel(channelName);
        // $toasted.show(`Connected to channel ${channelName}`, { type: 'success', icon: 'check-circle' });
        console.log('Socket store, channel', channelName, ', join OK:', response);
        if (!response) {
          $log.error('Socket store: channel join, no response');
          return;
        }

        if ('active_requests' in response) {
          if ('help' in response.active_requests) {
            const toastsStore = useToastsStore();
            toastsStore.activeHelpRequests({ count: response.active_requests.help });
          }
        }
      })
      .receive('error', ({ reason }: { reason: string; }) => {
        leftChannel(channelName);
        console.log('Socket store, channel', channelName, ', join error:', reason);
      })
      .receive('timeout', () => {
        leftChannel(channelName);
        console.log('Socket store, channel', channelName, ', join timeout (networking issue. Still waiting...)');
      });

    const HELP_REQUEST_ID = 4;

    // Events
    type MsgRequestCreated = {
      id: number;
      user_request_type_id: number;
      message: string;
      total_active_requests: number; // TODO was this renamed to active_requests?
      active_requests: number;
      source_user: { id: number; nickname: string; };
    };
    channel.on('created', (msg: MsgRequestCreated) => {
      /**
       * Received by the target user
       */
      // console.log(channelName, 'on "created"', msg);
      if (typeof msg === 'object' && msg && 'user_request_type_id' in msg && msg.user_request_type_id === HELP_REQUEST_ID) {
        // Update totals
        if ('active_requests' in msg) {
          const globalStore = useGlobalStore();
          globalStore.setReceivedHelpRequestsCount(msg.active_requests);
        }
        const toastsStore = useToastsStore();
        toastsStore.newHelpRequest({ sourceNickname: msg.source_user.nickname, count: msg.active_requests, id: msg.id });
      } else {
        printNotImplemented(channelName, 'created', msg);
      }
    });
    type MsgHelpRequestCompleted = {
      active_requests: number;
      id: number;
      message: string;
      source_user: { id: number; nickname: string; };
      target_user: { id: number; nickname: string; };
      user_request_type_id: number;
    };
    channel.on('completed', (msg: MsgHelpRequestCompleted) => {
      /**
       * Received by the target user (to decrement the number of active help requests)
       */
      // msg = {
      //   'active_requests': 9,
      //   'id': 205,
      //   'message': 'Help me, Mike!',
      //   'source_user': { 'id': 221, 'nickname': 'Alberto' },
      //   'target_user': { 'id': 215, 'nickname': 'Mike' },
      //   'user_request_type_id': 4,
      // };
      // console.log(channelName, 'on "completed"', msg);
      if (msg.user_request_type_id === HELP_REQUEST_ID && 'active_requests' in msg) {
        const globalStore = useGlobalStore();
        globalStore.setReceivedHelpRequestsCount(msg.active_requests);
        const toastsStore = useToastsStore();
        toastsStore.updateOrDismissActiveHelpRequestsToast({ count: msg.active_requests });
      } else {
        printNotImplemented(channelName, 'completed', msg);
      }
    });
    type MsgHelpRequestCancelled = {
      active_requests: number;
      source_user: { id: number; nickname: string; };
    };
    channel.on('cancelled', (msg: MsgHelpRequestCancelled) => {
      /**
       * Received by the target user
       */
      // console.log(channelName, 'on "cancelled"', msg);
      if (!$toasted) {
        $log.warn('showToast: no $toasted?');
      } else {
        $toasted.show(`${msg.source_user.nickname} cancelled the help request.`, { type: 'info', icon: 'info-circle' });
      }
      const globalStore = useGlobalStore();
      globalStore.setReceivedHelpRequestsCount(msg.active_requests);
      const toastsStore = useToastsStore();
      toastsStore.updateOrDismissActiveHelpRequestsToast({ count: msg.active_requests });
      // printNotImplemented(channelName, 'cancelled', msg);
    });
    type MsgHelpRequestPostponed = {
      user_request_type_id: number;
      active_requests: number;
    };
    channel.on('postponed', (msg: MsgHelpRequestPostponed) => {
      /**
       * Received by the target user (to decrement the number of active help requests)
       */
      // console.log(channelName, 'on "postponed"', msg);
      if (msg.user_request_type_id === HELP_REQUEST_ID && 'active_requests' in msg) {
        const globalStore = useGlobalStore();
        globalStore.setReceivedHelpRequestsCount(msg.active_requests);
        const toastsStore = useToastsStore();
        toastsStore.updateOrDismissActiveHelpRequestsToast({ count: msg.active_requests });
      } else {
        printNotImplemented(channelName, 'postponed', msg);
      }
    });
    type MsgHelpRequestAcknowledged = {
      user_request_type_id: number;
      target_user: { id: number; nickname: string; };
    };
    channel.on('acknowledged', (msg: MsgHelpRequestAcknowledged) => {
      /**
       * Received by the source user
       */
      console.log(channelName, 'on "acknowledged"', msg);
      // msg = {
      //   'id': 202,
      //   'message': 'the other way around',
      //   'source_user': { 'id': 215, 'nickname': 'Mike' },
      //   'target_user': { 'id': 221, 'nickname': 'Alberto' },
      //   'user_request_type_id': 4,
      // };
      if (msg.user_request_type_id === HELP_REQUEST_ID && 'target_user' in msg && 'nickname' in msg.target_user) {
        if (!$toasted) {
          $log.warn('showToast: no $toasted?');
        } else {
          $toasted.show(`${msg.target_user.nickname} noticed your alarm, help is on the way!`, { type: 'info', icon: 'info-circle' });
        }
      } else {
        $log.error(`Channel ${channelName}, acknowledged event: unknown user_request_type_id or no target_user nickname. Msg:`, msg);
      }
    });
  }

  // function joinChannelNuggets({ commit, getters, rootGetters }) {
  //   const { socket } = getters;
  //   if (!socket) {
  //     $log.info('Socket store: joinChannelRequests: no socket');
  //     return;
  //   }
  //
  //   const userId = rootGetters['account/userProfile'].id;
  //   const channelName = `nuggets:${userId}`;
  //   $log.info('Socket store: joinChannelRequests: channel name:', channelName);
  //   const roomToken = '';
  //   const channel = socket.channel(channelName, { token: roomToken });
  //
  //   // $input.onEnter(e => {
  //   //   // Send message
  //   //   channel.push('new_msg', { body: e.target.val }, 10000)
  //   //     .receive('ok', msg => console.log(channelName, 'created message', msg))
  //   //     .receive('error', reasons => console.log(channelName, 'create failed', reasons))
  //   //     .receive('timeout', () => console.log(channelName, 'Networking issue...'));
  //   // });
  //
  //   channel.join()
  //     .receive('ok', (response) => {
  //       joinedChannel(channelName);
  //       // $toasted.show(`Connected to channel ${channelName}`, { type: 'success', icon: 'check-circle' });
  //       console.log('Socket store, channel', channelName, ', join OK:', response);
  //       if (!response) {
  //         $log.error('Socket store: channel join, no response');
  //         return;
  //       }
  //
  //       if ('active_nuggets' in response && response.active_nuggets) {
  //         const toastHtml = `You have ${response.active_nuggets} active Tutorial Nugget(s).`;
  //         // $toasted.show(toastHtml, { type: 'info', icon: 'info-circle' });
  //         // $toasted.show(toastHtml, { type: 'info', icon: 'info-circle' });
  //         $toasted.show(toastHtml, {
  //           // className: isHelpRequest ? 'toast-urgent' : 'dark-toast',
  //           // className: 'toast-urgent',
  //           type: 'info',
  //           icon: 'info-circle',
  //           // theme: 'bubble', // ['toasted-primary' (default), 'outline', 'bubble']
  //           duration: 0,
  //           action: {
  //             text: 'Open',
  //             onClick: (e: Event, toastObject: ToastObject) => {
  //               toastObject.goAway(0);
  //               router.push({ name: 'Nuggets' });
  //             },
  //           },
  //         });
  //       }
  //     })
  //     .receive('error', ({ reason }) => {
  //       leftChannel(channelName);
  //       console.log('Socket store, channel', channelName, ', join error:', reason);
  //     })
  //     .receive('timeout', () => {
  //       leftChannel(channelName);
  //       console.log('Socket store, channel', channelName, ', join timeout (networking issue. Still waiting...)');
  //     });
  //
  //   // Events
  //   channel.on('created', (msg) => {
  //     console.log(channelName, 'on "created"', msg);
  //     // Body:
  //     // {
  //     //   id: <request_id>,
  //     //   user_request_type_id: <type_id>,
  //     //   message: <string>,
  //     //   total_active_requests: <integer>
  //     // }
  //     // Help request type id is 4
  //     printNotImplemented(channelName, 'created', msg);
  //   });
  //   channel.on('completed', (msg) => {
  //     console.log(channelName, 'on "completed"', msg);
  //     printNotImplemented(channelName, 'completed', msg);
  //   });
  //   channel.on('postponed', (msg) => {
  //     console.log(channelName, 'on "postponed"', msg);
  //     printNotImplemented(channelName, 'postponed', msg);
  //   });
  // }

  type MsgMessagingNewMessage = {
    channel_id: string;
    body: string;
    created_by: {
      avatar: string;
      nickname: string;
    };
  };
  function showNewMessageToast(msg: MsgMessagingNewMessage) {
    if (!$toasted) {
      $log.warn('showToast: no $toasted?');
    } else {
      const accountStore = useAccountStore();

      const sanitizedBody = sanitizeHtml(msg.body, {
        allowedTags: [], // remove all tags
      });
      const toastHtml = `
      <div class="toast-messaging-new-message">
        <div class="avatar-wrapper align-self-start">
          <img src="${msg.created_by.avatar}">
        </div>
        <div class="nickname-message-and-date">
          <div class="d-flex">
            <div
              v-if="props.row.participant"
              class="participant-nickname"
            >
              ${msg.created_by.nickname}
            </div>
            <!--              <div class="message-date align-self-end ml-gap-0_38x">-->
            <!--                {{ translationStore.getLocalFormattedTime(props.row.last_message_inserted_at) }} | {{ translationStore.getLocalFormattedDate(props.row.last_message_inserted_at) }}-->
            <!--              </div>-->
          </div>
          <div
            class="message-body allow-new-line"
          >
            ${sanitizedBody}
          </div>
        </div>
      </div>
    `;
      $toasted.show(toastHtml, {
        // className: isHelpRequest ? 'toast-urgent' : 'dark-toast',
        // className: 'toast-urgent',
        type: 'info',
        // theme: 'bubble', // ['toasted-primary' (default), 'outline', 'bubble']
        duration: 0,
        action: {
          text: 'Open',
          onClick: (e: Event, toastObject: ToastObject) => {
            toastObject.goAway(0);
            let routeName = '';
            if (accountStore.userRoleIsAdministrator) {
              routeName = 'AdminMessageCenter';
            } else if (accountStore.userRoleIsJgoPartner) {
              routeName = 'PartnerMessageCenter';
            } else {
              $log.error('New message toast: open action: user role is not administrator nor partner.');
              return;
            }
            router.push({ name: routeName, query: { id: msg.channel_id } });
          },
        },
      });
    }
  }
  // setTimeout(() => showNewMessageToast({
  //   channel_id: '1234',
  //   body: 'Hello',
  //   created_by: {
  //     avatar: '',
  //     nickname: 'Mike',
  //   },
  // }));

  function joinChannelMessaging() {
    if (!socket) {
      $log.info('Socket store: joinChannelRequests: no socket');
      return;
    }

    const accountStore = useAccountStore();
    const userId = accountStore.userProfile?.id;
    const channelName = `messaging:${userId}`;
    $log.info('Socket store: joinChannelRequests: channel name:', channelName);
    const roomToken = '';
    const channel = socket.channel(channelName, { token: roomToken });

    // $input.onEnter(e => {
    //   // Send message
    //   channel.push('new_msg', { body: e.target.val }, 10000)
    //     .receive('ok', msg => console.log(channelName, 'created message', msg))
    //     .receive('error', reasons => console.log(channelName, 'create failed', reasons))
    //     .receive('timeout', () => console.log(channelName, 'Networking issue...'));
    // });

    type MsgMessagingJoinResponse = {};
    channel.join()
      .receive('ok', (response: MsgMessagingJoinResponse) => {
        joinedChannel(channelName);
        // $toasted.show(`Connected to channel ${channelName}`, { type: 'success', icon: 'check-circle' });
        console.log('Socket store, channel', channelName, ', join OK:', response);
        if (!response) {
          $log.error('Socket store: channel join, no response');
          // return;
        }

        // if ('active_requests' in response) {
        //   if ('help' in response.active_requests) {
        //     const count = response.active_requests.help;
        //     if (count) {
        //       $toasted.show(`You have ${count} active help requests.`, { type: 'info', icon: 'info-circle' });
        //     }
        //   }
        // }
      })
      .receive('error', ({ reason }: { reason: string }) => {
        leftChannel(channelName);
        console.log('Socket store, channel', channelName, ', join error:', reason);
      })
      .receive('timeout', () => {
        leftChannel(channelName);
        console.log('Socket store, channel', channelName, ', join timeout (networking issue. Still waiting...)');
      });

    // Events
    channel.on('new_message', (msg: MsgMessagingNewMessage) => {
      console.log(channelName, 'on "new_message"', msg);
      const topicId = msg.channel_id;
      if (topicId in messagingTopicListeners.value) {
        if (typeof messagingTopicListeners.value[topicId] === 'function') {
          messagingTopicListeners.value[topicId](msg);
          return; // return here, no need to send the toast
        }
        $log.error(`messagingTopicListeners has key for topic ${topicId} but it is not a function.`);
      }
      showNewMessageToast(msg);
    });
  }

  function listenForMessageCenterMessages({ topicId, callbackFn }: { topicId: string; callbackFn: Function; }) {
    // console.log('listen', topicId, callbackFn);
    messagingTopicListeners.value[topicId] = callbackFn;
  }

  function unlistenForMessageCenterMessages(topicId: string) {
    // console.log('unlisten', topicId);
    delete messagingTopicListeners.value[topicId];
  }

  function createAndConnectToSocket() {
    // const createdDate = new Date(); // for debugging onClose
    const authStore = useAuthStore();
    const params = { token: authStore.accessToken };
    // const params = { token: `${authStore.accessToken}-invalid` }; // to test invalid token
    // The default reconnect ms is: [10, 50, 100, 150, 200, 250, 500, 1000, 2000][tries - 1] || 5000, too fast!
    const reconnectAfterMs = (tries: number) => {
      const ms = [5000, 20000, 30000][tries - 1] || 60000; // 5s, 20s, 30s, 60s
      $log.debug(`reconnectAfterMs: retry #${tries} = ${ms}`);
      return ms;
    };

    // const params = { token: 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJhZG1pbiI6dHJ1ZSwiYXVkIjoiaXNzdWVyIiwiZXhwIjoxNTc5MjAwNjk0LCJpYXQiOjE1NzkxOTcwOTQsImlzcyI6Imlzc3VlciIsImp0aSI6IjFlNjUxMGZjLWY0NDktNGVlMi04YWVhLTRkMDRlYTc1YzczMiIsIm5iZiI6MTU3OTE5NzA5MywicGNwX3VzZXIiOnsicm9sZXMiOlsiYWRtaW4iXX0sInN1YiI6IlVzZXI6MTQ4IiwidHlwIjoiYWNjZXNzIn0.gF4YHFjRGPaMZwSVOWb1nA353umsvMBnf1mwgG5rT7XEg7TnJ5snZgR7Lie09xE9kz5wsPIWOyOMi8XYfsxIMA' };
    socket = new Socket(socketUrl, { params, reconnectAfterMs });
    $log.info('Socket store: createAndConnectToSocket: socket:', socket);

    socket.onError(async (error: unknown) => {
      // error argument says nothing about the error:
      // https://elixirforum.com/t/phoenix-websocket-error-exception-handling/22795/4
      errors.value.push(error);
      const logTag = `Socket onError (#${errors.value.length})`;
      socketIsConnected.value = socket.isConnected();
      if (socketIsConnected.value) {
        $log.debug(`${logTag} but still connected:`, error);
        // Do nothing?
        return;
      }
      // Socket not connected.
      socket.disconnect(); // so that teardown is done and the callbacks stop being called
      // Maybe the token is invalid

      const maxErrorsUntilDisconnect = 3;
      if (errors.value.length < maxErrorsUntilDisconnect) {
        // This error is already a result of a connection try, so this if will happen twice (for next retry #2 and retry #3)
        $log.debug(`${logTag} and not connected: invalid token? Will check it in 10 seconds.`);
        setTimeout(async () => {
          try {
            // If the token is invalid, /auth/valid will return 401 Unauthorized,
            // which will be intercepted, a new token will be requested, and this request will be repeated with the new token.
            const response = await $http?.get('/auth/valid');
            if (response?.data === 'ok') {
              $log.debug(`${logTag}: token is valid`);
            } else {
              $log.warn(`${logTag}: unexpected response from /auth/valid: ${response?.data}`);
            }
          } catch (checkTokenError: unknown) {
            const BAD_GATEWAY: 502 = 502;
            if (typeof checkTokenError === 'object'
                && checkTokenError
                && 'response' in checkTokenError
                && typeof checkTokenError.response === 'object'
                && checkTokenError.response
                && 'status' in checkTokenError.response
                && checkTokenError.response.status === BAD_GATEWAY) {
              $log.debug(`${logTag}: could not check token (502. deploying?).`);
            } else {
              $log.error(`${logTag}: could not check token (not 502):`, error);
            }
          } finally {
            // Reconnect/retry
            $log.debug(`${logTag}: will build a new socket.`);
            createAndConnectToSocket();
          }
        }, 10000);
      } else {
        const nextRetryMinutes = 5;
        const nextRetryMs = 1000 * 60 * nextRetryMinutes;
        $log.warn(`${logTag}: tried ${errors.value.length} times. Retrying in ${nextRetryMinutes} minutes.`);
        clearTimeout(retryTimeout.value);
        // @ts-ignore
        retryTimeout.value = setTimeout(() => {
          errors.value = [];
          $log.debug(`${logTag}: cleared previous errors: ${errors.value}`);
          createAndConnectToSocket();
        }, nextRetryMs);
      }
    });

    socket.onClose(() => {
      // console.log('Socket store: socket onClose', error);
      // console.log(createdDate.toString());
      socketIsConnected.value = socket.isConnected();
    });

    socket.onOpen(() => {
      errors.value = [];
      console.log('Socket store: socket onOpen (connected)');
      socketIsConnected.value = socket.isConnected();
      const globalStore = useGlobalStore();
      const { featureToggle } = globalStore;
      if (appName === 'imp') {
        if (featureToggle.helpButton) {
          joinChannelRequests();
        }
        // dispatch('joinChannelNuggets');
      } else if (appName === 'jgo') {
        joinChannelMessaging();
        // } else if (appName === 'tly') {
        //   joinChannelMessaging();
      }
    });

    socket.connect();
  }

  function initialize() {
    const authStore = useAuthStore();
    if (!authStore.isAuthenticated) {
      $log.info('Socket store: initialize: not authenticated; stop.');
      return;
    }
    createAndConnectToSocket();
  }

  return {
    //
    // State
    //
    socketIsConnected,
    channelsJoinStatus,
    errors,
    retryTimeout,
    messagingTopicListeners,
    //
    // Getters
    //
    connectedToHelpRequests,
    //
    // Actions
    //
    injectRouter,
    resetState,
    initialize,
    // joinedChannel,
    // leftChannel,
    // createAndConnectToSocket,
    // joinChannelRequests,
    // joinChannelNuggets,
    // joinChannelMessaging,
    listenForMessageCenterMessages,
    unlistenForMessageCenterMessages,
  };
});

// eslint-disable-next-line import/prefer-default-export
export { useSocketStore };
