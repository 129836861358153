// Pinia Store
import { computed, inject, ref } from 'vue';
import type { RouteLocationNormalized, Router } from 'vue-router';
import type { AxiosStatic } from 'axios';
import { defineStore } from 'pinia';

import type { ClientRaw } from '@/stores/api';
// eslint-disable-next-line import/no-cycle
import { useAuthStore } from '@/stores/auth';
// eslint-disable-next-line import/no-cycle
import {
  type ProfileClient, type ProfileRaw, useAccountStore, type UserRole,
} from '@/stores/account';
// eslint-disable-next-line import/no-cycle
import { useSocketStore } from '@/stores/socket';

// import globalConstants, { type GetReadyProgramImp, type GetReadyProgramJgo, type GetReadyProgramTly } from '@/globalConstants';
import globalConstants from '@/globalConstants';
import { differenceInMinutes } from 'date-fns';

import type { ImpulseComment } from '@/components/logbook/ImpulseComments.vue';
import type { Entity, EntityBlockPerson } from '@/components/impulse-area/EntityBlockWithChart.vue';
import type { Aggregator } from '@/views/ImpulseArea.vue';
import type { DigOrPerfectCandidateEntityBlock } from '@/components/tly/treasure-quest/JobCheck.vue';
import type { NuggetElementRaw } from '@/components/management/learning-nuggets/AdminLearningNuggets.vue';
import type { TableClientsRow } from '@/components/management/TableClients.vue';
import type { TableAccountsRow } from '@/components/management/TableAccounts.vue';

const appName = import.meta.env.VITE_APP_NAME;
const isAppImp = appName === 'imp' || appName === 'zly'; // 2022-04-13: added zly here to help with the creation of zly
const isAppJgo = appName === 'jgo';
const isAppTly = appName === 'tly' || appName === 'zae'; // TODO: remove zae
const isAppZly = appName === 'zly';
const isAppZae = appName === 'zae';

const {
  ALL_ROLES,
  USER_JOURNEY_TALENT_MANUAL_PAGES,
  USER_JOURNEY_V3_PAGES,
} = globalConstants;

console.log('Pinia Global store is being created.'); // no access to Vue.prototype.$log here yet

// Feature detect + local reference
let storage: Storage | null;
let fail;
let uid;
try {
  uid = new Date().toString();
  (storage = window.localStorage).setItem(uid, uid);
  fail = storage.getItem(uid) !== uid.toString();
  storage.removeItem(uid);
  if (fail) {
    storage = null;
  }
} catch (exception) {
  const exceptionName = exception && typeof exception === 'object' && 'name' in exception && exception.name;
  console.log(`Could not access the local storage: ${exceptionName}`);
}

// eslint-disable-next-line no-unused-vars
let router: Router;
let loadingPeople = false;
let loadingPeopleToRefreshImages = false;
let loadingGroups = false;
let alarmsTimeout: number;
let lastErrorFetchingImageTime: Date;

type Theme = {
  id: string;
  name: string;
};

const AVAILABLE_THEMES: Theme[] = [
  { id: 'v2', name: 'v2, 2019' },
  { id: 'v3e', name: 'v3 e, 2020' },
];

const DEFAULT_THEME_INDEX = 1; // v3e

function getThemeFromEnv() {
  let envThemeId = AVAILABLE_THEMES[DEFAULT_THEME_INDEX].id; // the default, unless overridden by an env var
  if (window.ENV && window.ENV.THEME) {
    // set when starting the container, reading from AWS task definition environment variables
    envThemeId = window.ENV.THEME.toLowerCase();
  } else if (import.meta.env.VITE_APP_THEME) {
    // Not found on window.ENV, let's try the process
    envThemeId = import.meta.env.VITE_APP_THEME.toLowerCase();
  }
  return AVAILABLE_THEMES.find((theme) => theme.id === envThemeId) || AVAILABLE_THEMES[DEFAULT_THEME_INDEX];
}

type IndividualResponse = {
  data: {
    empty_chart: null | string;
    motivation: null | string;
    next_impulse_datetime: null | string;
    no_permission_chart: null | string;
    people: PersonRaw[];
  }
};

type GroupsResponse = {
  data: {
    data: GroupRaw[];
    motivation: null | string;
  }
};

export type ActiveOrBasicRow = {
  alias: string;
  color: string;
  fix_values: null;
  label: string;
  type: string;
  value: number;
};

export type ProfileBlockActiveOrBasic = {
  chart_image: null | string;
  chart_size: null | {
    height: number;
    width: number;
  };
  color: null | string;
  element: null | string;
  mask: string;
  rows: ActiveOrBasicRow[];
  segment: null | number;
  x: null | number;
  y: null | number
};

export type ProfileBlockDevelopment = {
  color: string;
  element: string;
  segment: number;
  x: number;
  y: number;
};

export type PersonRaw = {
  active: ProfileBlockActiveOrBasic;
  avatar: string | null;
  basic: ProfileBlockActiveOrBasic;
  can_access_talent_manual: boolean;
  development_primary: ProfileBlockDevelopment;
  development_secondary: ProfileBlockDevelopment;
  entity_type_id: number;
  first_name: string;
  group_condition: boolean;
  has_talent_manual: boolean;
  id: number;
  individual_condition: boolean;
  is_active: boolean;
  is_avatar: boolean;
  is_impulse_generation_active: boolean;
  is_selected: null;
  last_name: string;
  nickname: string;
  position: null;
  privacy_control: boolean;
  profile_owner: boolean;
  requires_attention: boolean;
  role: {
    id: number;
    name: string;
  };
  roles: {
    id: number;
    name: string;
  }[];
  session_user: boolean;
  talent_manual_access: boolean;
  //
  impulse?: null | Impulse;
};

export type EntityBlockRaw = { // from own account, or from /session/entities/${id}/block
  active: ProfileBlockActiveOrBasic;
  avatar: string;
  basic: ProfileBlockActiveOrBasic;
  can_access_talent_manual: boolean;
  entity_type_id: number;
  group_condition: boolean;
  has_talent_manual: boolean;
  id: number;
  individual_condition: boolean;
  is_avatar: boolean;
  nickname: string;
  position: string;
  profile_owner: boolean;
  requires_attention: boolean;
  role: {
    id: number;
    name: string;
  };
  session_user: boolean;
};

export type CockpitPersonRaw = {
  active: ProfileBlockActiveOrBasic;
  avatar: string;
  basic: ProfileBlockActiveOrBasic;
  entity_type_id: number;
  first_name: string;
  id: number;
  last_name: string;
  nickname: string;
  position: null;
  requires_attention: boolean;
};

export type UserRawForAdmin = { // /users/${id} response
  account: {
    client: {
      avatar: null;
      display_name: string;
      id: number;
      inserted_at: string;
      is_consulting: boolean;
    };
    display_name: string;
    id: number;
  };
  activated_at: string;
  active: boolean;
  avatar: string;
  birth_day: null;
  birth_month: null;
  birth_year: number;
  country_nationality: null | {
    aircraft_code: string;
    code: string;
    id: number;
    name: string;
  };
  country_origin: null | {
    aircraft_code: string;
    code: string;
    id: number;
    name: string;
  };
  country_residence: {
    aircraft_code: string;
    code: string;
    id: number;
    name: string;
  };
  country_work: null | {
    aircraft_code: string;
    code: string;
    id: number;
    name: string;
  };
  email: string;
  first_name: string;
  gender: {
    data: { // TODO backend should remove this "data" wrapper?
      id: number;
      name: string;
    }
  };
  has_talent_manual: boolean;
  id: number;
  is_avatar: boolean;
  is_example_user: boolean;
  is_service_contact: boolean;
  last_activity_datetime: string;
  last_name: string;
  locked: boolean;
  mask: string;
  native_language: null;
  nickname: string;
  onboarding_step: number;
  personal_email: null;
  position: null;
  receive_invitation_cc: boolean;
  registered_at: string;
  roles: {
    id: number;
    name: string;
  }[];
  service_contact_order: null;
  settings: {
    is_example_user: boolean;
    is_service_contact: boolean;
    receive_invitation_cc: boolean;
    service_contact_order: null;
  };
  timezone: string;
  username: string;
};

export type TutorialImpulse = {
  color: string;
  description: string;
  id: number;
  image: string;
  learning_program_impulse_id: number; // needed to call /session/learning_program/advance
  text: string;
  transcript: {
    order: number;
    section: string;
    text: string;
  }[];
  tutorial_impulse_count: number;
  tutorial_impulse_order: number;
  video: null;
};

const DOING_CONFIRMATION_STATUS = {
  PENDING: 1,
  DONE: 2,
  NOT_DONE: 3,
  EXPIRED: 4,
} as const;

export type DoingReviewStatusId = typeof DOING_CONFIRMATION_STATUS[keyof typeof DOING_CONFIRMATION_STATUS];

export type Impulse = {
  actions: { allow_postpone: boolean; allow_skip: boolean; };
  color: string;
  comments: ImpulseComment[]
  description: string;
  doing_review: null | {
    doing_review_status_id: DoingReviewStatusId;
    id?: number;
    user_notes: null;
  };
  hour: number;
  id: number,
  image: string;
  impulse_category_id: number;
  impulse_date: string;
  impulse_datetime: string;
  individual_empowerment_area_id: null;
  minute: number;
  rating: null | number;
  status: { id: number; name: string; }
  status_id: number;
  text: string;
  transcript: { order: number; section: string; text: string; }[]
  tutorial_impulse: null | TutorialImpulse;
  video: null | string;
};

export type GroupRaw = {
  active: ProfileBlockActiveOrBasic;
  avatar: string;
  basic: ProfileBlockActiveOrBasic;
  entity_type_id: number;
  has_talent_manual: boolean;
  id: number;
  nickname: string;
  position: null | string;
  requires_attention: boolean;
};

type Group = GroupRaw & {
  visible: boolean;
  isActiveMode: boolean;
};

export type Nugget = {
  color: null | string;
  component: {
    id: number;
    module_id: number;
    name: string; // e.g. "Impulze block"
  };
  description: string;
  id: number;
  title: string;
  // postponed_to: string; // does not exist?
};

export type NuggetContentRaw = {
  id: number;
  language_id: 1 | 2;
  media: string | null;
  order: number;
  text: string | null;
  type: {
    id: 1 | 2 | 3;
    name: 'text' | 'video' | 'image';
  };
};

export type UserNuggetDetailsRaw = {
  color: null | string;
  completed_at: null | string;
  content: NuggetContentRaw[]; // is plural (contents) for the Admin
  description: string;
  element: NuggetElementRaw | null;
  id: number;
  image: null;
  inserted_at: string;
  postponed_to: string;
  series: {
    description: string;
    id: number;
    language_id: 1 | 2;
    module: {
      description: string;
      id: number;
      name: string;
    };
    name: string;
  };
  status: {
    id: number;
    name: string;
  };
  title: string;
  video: null;
};

export type PersonalDevelopmentAreaArchiveData = {
  color: string;
  doing_review: null | {
    doing_review_status_id: DoingReviewStatusId;
    id: number;
    user_notes: null; // TODO string?
  };
  has_comments: boolean;
  id: number;
  impulse_date: string;
  impulse_datetime: string;
  impulse_id: number;
  individual_empowerment_area_id: null | number;
  rating: null | number;
  status_id: number;
};

// type JobDigTemporaryEntityActiveOrBasic = {
//   chart_image: string;
//   chart_size: {
//     height: number;
//     width: number;
//   };
//   description: string;
//   help: string;
//   images: string[];
//   rows: {
//     alias: string;
//     color: string;
//     fix_values: null;
//     label: string;
//     type: string;
//     value: number;
//   }[];
//   title: string;
//   type: number;
// };

// type JobDigTemporaryEntity = {
//   nickname: string;
//   visible: boolean;
//   isActiveMode: boolean;
//   questionnaireId: number;
//   has_talent_manual: boolean;
//   active: JobDigTemporaryEntityActiveOrBasic;
//   basic: JobDigTemporaryEntityActiveOrBasic;
// };

// export type JobEntity = {
//   id: string; // 'result'?
//   nickname: string;
//   visible: boolean;
//   imgSrc: string | null | undefined;
//   questionnaireId: number | null | undefined;
//   has_talent_manual: boolean;
// };

// eslint-disable-next-line import/prefer-default-export
export const useGlobalStore = defineStore('global', () => {
  // const router = useRouter(); // not working, will be injected via action
  const $log: any = inject('$log');
  const $http: undefined | AxiosStatic = inject('$http');

  const globalLoadingCounter = ref(0);
  const currentVersion = ref<null | string>(null);
  const currentApiVersion = ref<null | string>(null);
  const newVersionAvailable = ref<null | string>(null);
  const showWhatsNew = ref(false);
  const people = ref<EntityBlockPerson[]>([]);
  const peoplePromise = ref<undefined | Promise<undefined | IndividualResponse>>(undefined);
  const individualMotivation = ref('');
  const selectedEntityType = ref<null | number>(null);
  const selectedPerson = ref<null | EntityBlockPerson>(null);
  const groups = ref<Group[]>([]);
  const groupsPromise = ref<undefined | Promise<undefined | GroupsResponse>>(undefined);
  const selectedGroup = ref<null | Group>(null);
  const previouslySelectedPersonBeforeSelectingTeamOrGroup = ref<null | EntityBlockPerson>(null);
  const temporarySelectedEntity = ref<null | EntityBlockPerson | UserRawForAdmin | EntityBlockPerson | Aggregator | DigOrPerfectCandidateEntityBlock | Entity>(null);
  const temporarySelectedClient = ref<null | {}>(null);
  const temporarySelectedClientAccount = ref<null | {}>(null);
  const answeringQuestionnaireFor = ref<null | {
    firstName: string;
    lastName: string;
    target_user: {
      id: number;
    };
  }>(null);
  const nextImpulseDateTime = ref<null | string>(null);
  const receivedHelpRequestsCount = ref(0);
  const receivedNuggetsCount = ref(0);
  const nuggetsHighlights = ref<Nugget[]>([]);
  const availableThemes = ref<Theme[]>(AVAILABLE_THEMES);
  const selectedTheme = ref<Theme>(getThemeFromEnv());
  const talentManualElementInteractionSwitches = ref({
    showAllQualityCheckMarkers: false,
    showPersonQualityCheck: false,
    showCrossedWords: true,
  });
  const tlyCurrentJob = ref<null | {}>(null);
  const previousRoute = ref<null | RouteLocationNormalized>(null);
  const forceHideDebugButtons = ref(true);
  const numberOfActiveImpulsesForEachRole = ref<{
    // [ALL_ROLES.IMP_TEAM_LEADER.id]: number;
    // 3?: { pendingImpulsesCount: number, impulseDatetime: string }; // TODO key type
    // [ALL_ROLES.IMP_TEAM_MEMBER.id]: number;
    // 4?: { pendingImpulsesCount: number, impulseDatetime: string }; // TODO key type
    [key: number]: { pendingImpulsesCount: number, impulseDatetime: string };
  }>({});
  const personalDevelopmentAreaDataByRole = ref<{
    // [ALL_ROLES.IMP_TEAM_LEADER.id]: number;
    3?: PersonalDevelopmentAreaArchiveData[]; // TODO key type
    // [ALL_ROLES.IMP_TEAM_MEMBER.id]: number;
    4?: PersonalDevelopmentAreaArchiveData[]; // TODO key type
  }>({});
  const learningAndDoingCountByRole = ref<{
    [key: string]: { learning_count: number; doing_count: number; };
  }>({});
  const events = ref({
    headerAnimateMenuToTeamLeaderInvitations: false,
  });

  // const isDevelopmentEnvironment = computed(() => {
  //   // @ts-ignore // Cannot find name 'process'. Do you need to install type definitions for node?
  //   const isLocalDev = process.env.NODE_ENV === 'development';
  //   const hasDevEnv = window.ENV && window.ENV.ENVIRONMENT && window.ENV.ENVIRONMENT.toUpperCase() === 'DEV';
  //   return isLocalDev || hasDevEnv;
  // });
  const isProduction = computed(() => {
    // @ts-ignore // Cannot find name 'process'. Do you need to install type definitions for node?
    const isLocalDev = process.env.NODE_ENV === 'development';
    if (isLocalDev) {
      return false;
    }
    const accountStore = useAccountStore();
    if (isAppJgo && !accountStore.userHasAdministratorRole) {
      if (window.ENV && window.ENV.ENVIRONMENT && ['DEV', 'DEVELOPMENT'].includes(window.ENV.ENVIRONMENT)) {
        return false;
      }
      return true; // make it "production" even in staging
    }
    // if (isAppTly && !accountStore.userHasAdministratorRole) {
    //   return true; // make it "production" even in staging
    // }
    return !window.ENV || !window.ENV.ENVIRONMENT || window.ENV.ENVIRONMENT.toUpperCase() === 'PRODUCTION';
  });
  const showDebugButtons = computed((): boolean => {
    if (forceHideDebugButtons.value) {
      return false;
    }
    return !isProduction.value && window.ENV && window.ENV.SHOW_DEBUG_BUTTONS === 'true';
  });
  //
  const userJourneyTalentManualPages = computed(() => USER_JOURNEY_TALENT_MANUAL_PAGES);
  const userJourneyTalentManualPagesPhase1 = computed(() => USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_1);
  const userJourneyTalentManualPagesPhase2 = computed(() => USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_2);
  const userJourneyTalentManualPagesPhase3 = computed(() => USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_3);
  const userJourneyTalentManualPagesPhase4 = computed(() => USER_JOURNEY_TALENT_MANUAL_PAGES.PHASE_4);
  //
  const userJourneyV3Pages = computed(() => USER_JOURNEY_V3_PAGES);
  const userJourneyV3PagesPhase2 = computed((): { [pageKey: string]: number } => USER_JOURNEY_V3_PAGES.PHASE_2); // GetReadyProgramImp | GetReadyProgramJgo | GetReadyProgramTly

  const peopleWithProfile = computed(() => people.value.filter((person) => !!person.id));
  const ownProfile = computed((): undefined | EntityBlockPerson => people.value.find((person) => person.session_user === true));
  const featureToggle = computed(() => {
    const developmentFeatures = {
      coPilot: true,
      accessControl: true,
      mobileApps: true,
      nuggets: true,
      helpButton: true,
      deleteAccount: true,
      invitationsForTalentManual: true,
      individualEmpowerment: true,
      individualEmpowermentAnalytics: true,
      personalDevelopmentArea: true,
      personalDevelopmentAreaAreas: [
        'PersonalDevelopmentArea__DevelopmentPath',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneMonth',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneQuarter',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneYear',
        'PersonalDevelopmentArea__SoftSkillsCertificate',
        'PersonalDevelopmentArea__SoftSkillsCertificate__Introduction',
        'PersonalDevelopmentArea__SoftSkillsCertificate__TeamMemberCertificate',
        'PersonalDevelopmentArea__SoftSkillsCertificate__TeamLeaderCertificate',
      ],
      doersJournal: true,
      topAndLowSoftFactors: true,
    };

    const stagingFeatures = {
      coPilot: true,
      accessControl: true,
      mobileApps: true,
      nuggets: false,
      helpButton: selectedTheme.value?.id !== 'v3e',
      deleteAccount: true,
      invitationsForTalentManual: true,
      individualEmpowerment: true,
      individualEmpowermentAnalytics: true,
      personalDevelopmentArea: true,
      personalDevelopmentAreaAreas: [
        'PersonalDevelopmentArea__DevelopmentPath',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneMonth',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneQuarter',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneYear',
        'PersonalDevelopmentArea__SoftSkillsCertificate',
        'PersonalDevelopmentArea__SoftSkillsCertificate__Introduction',
        'PersonalDevelopmentArea__SoftSkillsCertificate__TeamMemberCertificate',
        'PersonalDevelopmentArea__SoftSkillsCertificate__TeamLeaderCertificate',
      ],
      doersJournal: true,
      topAndLowSoftFactors: isAppImp || isAppTly || isAppZae,
    };

    const productionFeatures = {
      coPilot: true,
      accessControl: true,
      mobileApps: true,
      nuggets: false,
      helpButton: selectedTheme.value?.id !== 'v3e',
      deleteAccount: false,
      invitationsForTalentManual: true,
      individualEmpowerment: true,
      individualEmpowermentAnalytics: false,
      personalDevelopmentArea: true,
      personalDevelopmentAreaAreas: [
        'PersonalDevelopmentArea__DevelopmentPath',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneMonth',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneQuarter',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneYear',
        'PersonalDevelopmentArea__SoftSkillsCertificate',
        'PersonalDevelopmentArea__SoftSkillsCertificate__Introduction',
        // 'PersonalDevelopmentArea__SoftSkillsCertificate__TeamMemberCertificate',
        // 'PersonalDevelopmentArea__SoftSkillsCertificate__TeamLeaderCertificate',
      ],
      doersJournal: isAppImp,
      topAndLowSoftFactors: isAppImp || isAppTly || isAppZae,
    };

    if (window.ENV && window.ENV.ENVIRONMENT) {
      switch (window.ENV.ENVIRONMENT.toUpperCase()) {
        case 'DEV':
        case 'DEVELOPMENT':
          return developmentFeatures;
        case 'STAGING':
          return stagingFeatures;
        case 'PRODUCTION':
        default:
          return productionFeatures;
      }
    }
    return productionFeatures; // default
  });
  const selectedThemeId = computed((): string => selectedTheme.value.id);
  const isThemeV3 = computed((): boolean => selectedThemeId.value === 'v3e');
  const isNotThemeV3 = computed((): boolean => selectedThemeId.value !== 'v3e');
  const isOldThemeV2 = computed((): boolean => selectedThemeId.value === 'v2');
  const personalDevelopmentAreaTotalDoingPointsForRole = computed(() => (role: UserRole) => { // TODO types
    const data = learningAndDoingCountByRole.value[role.id];
    if (!data) {
      return 0;
    }
    return data.doing_count;
  });
  const personalDevelopmentAreaTotalLearningPointsForRole = computed(() => (role: UserRole) => { // TODO types
    const data = learningAndDoingCountByRole.value[role.id];
    if (!data) {
      return 0;
    }
    return data.learning_count;
  });
  const elementsColor = computed(() => {
    // if (globalStore.selectedThemeId === 'v1') {
    //   return {
    //     fire: '#A01A1A',
    //     air: '#f6bf08',
    //     water: '#4FA096',
    //     earth: '#203362',
    //   };
    // }
    if (isOldThemeV2.value) {
      return {
        fire: '#d40d12',
        air: '#fcc814',
        water: '#28b09c',
        earth: '#033980',
      };
    }
    // default (v3e)
    return {
      fire: '#d9151d',
      air: '#fccf20',
      water: '#38baa8',
      earth: '#054a8f',
    };
  });

  function resetState() {
    globalLoadingCounter.value = 0;
    currentVersion.value = null;
    currentApiVersion.value = null;
    newVersionAvailable.value = null;
    showWhatsNew.value = false;
    people.value = [];
    peoplePromise.value = undefined;
    individualMotivation.value = '';
    selectedEntityType.value = null;
    selectedPerson.value = null;
    groups.value = [];
    groupsPromise.value = undefined;
    selectedGroup.value = null;
    previouslySelectedPersonBeforeSelectingTeamOrGroup.value = null;
    temporarySelectedEntity.value = null;
    temporarySelectedClient.value = null;
    temporarySelectedClientAccount.value = null;
    answeringQuestionnaireFor.value = null;
    nextImpulseDateTime.value = null;
    receivedHelpRequestsCount.value = 0;
    receivedNuggetsCount.value = 0;
    nuggetsHighlights.value = [];
    availableThemes.value = AVAILABLE_THEMES;
    selectedTheme.value = getThemeFromEnv();
    talentManualElementInteractionSwitches.value = {
      showAllQualityCheckMarkers: false,
      showPersonQualityCheck: false,
      showCrossedWords: true,
    };
    tlyCurrentJob.value = null;
    previousRoute.value = null;
    forceHideDebugButtons.value = true;
    numberOfActiveImpulsesForEachRole.value = {};
    personalDevelopmentAreaDataByRole.value = {};
    learningAndDoingCountByRole.value = {};
    events.value = {
      headerAnimateMenuToTeamLeaderInvitations: false,
    };
  }

  // function injectApp(appParam: App) {
  //   app = appParam;
  //   $log.debug('global: injectApp OK');
  // }

  function injectRouter(routerParam: Router) {
    router = routerParam;
  }

  function increaseGlobalLoadingCounter() {
    globalLoadingCounter.value += 1;

    // reset in case we forget to decrease it
    const timeoutSecs = 5;
    setTimeout(() => {
      // 5 seconds went by
      if (globalLoadingCounter.value > 0) {
        $log.warn(`global: increaseGlobalLoadingCounter: globalLoadingCounter is still > 0 (${globalLoadingCounter.value}) after ${timeoutSecs}s, resetting!`);
        globalLoadingCounter.value = 0;
      }
    }, timeoutSecs * 1000);
  }
  function decreaseGlobalLoadingCounter() {
    if (globalLoadingCounter.value === 0) {
      $log.error('global: decreaseGlobalLoadingCounter: globalLoadingCounter is already zero!');
      return;
    }
    globalLoadingCounter.value -= 1;
  }

  function setCurrentVersion({ version, showChanges }: { version: string; showChanges: boolean }) {
    const previousVersion = currentVersion.value;
    if (!previousVersion) {
      currentVersion.value = version;
      // No previous version; let's check the local storage
      if (storage) {
        const storagePreviousVersion = storage.getItem('current-version');
        if (storagePreviousVersion && storagePreviousVersion !== version) {
          // Different version, show "What’s new?"?
          if (showChanges) {
            showWhatsNew.value = true;
          }
        }
      }
    } else if (previousVersion !== version) {
      // Existing previous version and different: show "New version available"
      newVersionAvailable.value = version;
    }

    if (storage) {
      // Save to local storage so we can check it after the page reloads or at another time
      storage.setItem('current-version', version);
      if (previousVersion && previousVersion !== version) {
        // Set this so that after page refresh or login we can show "What's new?"
        storage.setItem('show-whats-new', showChanges.toString());
      }
    }
  }

  function setCurrentApiVersion({ version }: { version: string; }) {
    currentApiVersion.value = version;
  }

  // function resetShowWhatsNew() {
  //   showWhatsNew.value = false;
  // }

  function appComponentMounted() {
    // checkVersionChangedSinceLastTime();
    // checkAlarms();
  }

  function checkVersionChangedSinceLastTime() {
    const authStore = useAuthStore();
    if (!authStore.isAuthenticated) {
      return;
    }
    if (storage) {
      const showWhatsNewFromStorage = storage.getItem('show-whats-new');
      if (showWhatsNewFromStorage && showWhatsNewFromStorage !== 'false') {
        storage.removeItem('show-whats-new');
        showWhatsNew.value = true;
      }
    }
  }

  function setReceivedHelpRequestsCount(count: number) {
    receivedHelpRequestsCount.value = count;
  }

  async function checkAlarms() {
    if (import.meta.env.VITE_APP_NAME !== 'imp') {
      return;
    }
    const authStore = useAuthStore();
    if (!authStore.isAuthenticated) {
      return;
    }

    // eslint-disable-next-line no-bitwise
    // const randomString = [...Array(5)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
    let connectedToSocket = false;
    try {
      // If connected to the socket, do not poll.
      const socketStore = useSocketStore();
      // @ts-ignore
      connectedToSocket = socketStore.connectedToHelpRequests;
      if (connectedToSocket) {
        // Joined.
        $log.debug('checkAlarms: already connected to the socket "requests" channel: not polling.');
      } else {
        $log.debug('checkAlarms: not connected to the socket "requests" channel: polling.');
        // {help: {total: <int>}, nuggets: {total: <int>}}
        // $log.info(`Debug 20190701 | ${randomString}: will request /session/alarms`);
        type AlarmsResponse = {
          data: {
            help: {
              total: number;
            };
            nuggets: {
              total: number;
              list: Nugget[];
            };
          };
        };
        const response: undefined | AlarmsResponse = await $http?.get('/session/alarms', { signal: undefined });
        if (!response) {
          return;
        }
        // $log.info(`Debug 20190701 | ${randomString}: success requesting /session/alarms`);
        if ('help' in response.data && 'total' in response.data.help) {
          // $log.info(`Debug 20190701 | ${randomString}: help in response.data && total in response.data.help / before SET_RECEIVED_HELP_REQUESTS_COUNT`);
          setReceivedHelpRequestsCount(response.data.help.total);
          // $log.info(`Debug 20190701 | ${randomString}: after SET_RECEIVED_HELP_REQUESTS_COUNT`);
        }
        if ('nuggets' in response.data && 'total' in response.data.nuggets) {
          // $log.info(`Debug 20190701 | ${randomString}: nuggets in response.data && total in response.data.nuggets / before SET_RECEIVED_NUGGETS_COUNT`);
          nuggetsHighlights.value = [];
          receivedNuggetsCount.value = response.data.nuggets.total;
          // $log.info(`Debug 20190701 | ${randomString}: after SET_RECEIVED_NUGGETS_COUNT`);
          // Check nuggets component highlighting
          if ('list' in response.data.nuggets) {
            // $log.info(`Debug 20190701 | ${randomString}: list in response.data.nuggets, will forEach to check the nugget component for the highlights`);
            response.data.nuggets.list.forEach((nugget) => {
              if (nugget.component && !('postponed_to' in nugget && nugget.postponed_to)) { // TODO check if postponed_to exists in nugget
                nuggetsHighlights.value.push(nugget);
              }
            });
          }
        }
      }
    } catch (error) {
      // $log.info(`Debug 20190701 | ${randomString}: error requesting /session/alarms`);
      // $log.info(`Debug 20190701 | ${randomString}: checkAlarms(): error fetching:`, error);
      $log.info('checkAlarms(): error fetching:', error);
    } finally {
      if (!connectedToSocket) {
        // schedule a repeat (clear existing)
        const getRandomInteger = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;
        const nextRetryMs = getRandomInteger(30, 60) * 1000; // retry in 30 to 60 seconds
        clearTimeout(alarmsTimeout);
        alarmsTimeout = window.setTimeout(() => {
          checkAlarms();
        }, nextRetryMs);
      }
    }
  }

  function userSignedIn() {
    // called from auth store
    checkVersionChangedSinceLastTime();
    setTimeout(() => {
      // Some time for the Socket to connect
      checkAlarms();
    }, 3000);
  }

  function selectPerson(payload: undefined | EntityBlockPerson) {
    if (!payload) {
      $log.debug('selectPerson: no payload');
      selectedPerson.value = null;
      selectedGroup.value = null;
    } else {
      $log.debug('selectPerson:', payload.id, payload.nickname);
      selectedPerson.value = payload;
      selectedGroup.value = null;
    }
  }

  function setTemporarySelectedEntity(payload: EntityBlockPerson | UserRawForAdmin | Aggregator | DigOrPerfectCandidateEntityBlock | Entity) {
    $log.debug('setTemporarySelectedEntity', ('id' in payload && payload.id) || ('questionnaireId' in payload && payload.questionnaireId), payload.nickname);
    temporarySelectedEntity.value = payload;
  }

  function clearTemporarySelectedEntity() {
    $log.debug('clearTemporarySelectedEntity');
    temporarySelectedEntity.value = null;
  }

  function setTemporarySelectedClient(payload: ClientRaw | TableClientsRow | ProfileClient) {
    $log.debug('setTemporarySelectedClient', payload.id, ('description' in payload ? payload.description : '(no description property'));
    temporarySelectedClient.value = payload;
  }

  function clearTemporarySelectedClient() {
    $log.debug('clearTemporarySelectedClient');
    temporarySelectedClient.value = null;
  }

  function setTemporarySelectedClientAccount(payload: TableAccountsRow) {
    $log.debug('setTemporarySelectedClientAccount', payload.id, payload.description);
    temporarySelectedClientAccount.value = payload;
  }

  function clearTemporarySelectedClientAccount() {
    $log.debug('clearTemporarySelectedClientAccount');
    temporarySelectedClientAccount.value = null;
  }

  function selectPersonById(personId: number) {
    const foundPerson = people.value.find((person) => person.id === personId);
    if (foundPerson) {
      selectedPerson.value = foundPerson;
      selectedGroup.value = null;
    } else {
      $log.debug(`selectPersonById: person with ID ${personId} not found. deselecting current person`);
      $log.debug('List of people:', people.value);
      // deselect
      selectedPerson.value = null;
      selectedGroup.value = null;
    }
  }

  function selectGroupById(groupId: number) {
    const foundGroup = groups.value.find((group) => group.id === groupId);
    if (foundGroup) {
      $log.debug('selectGroupById, found group:', foundGroup.id, foundGroup.nickname);
      selectedPerson.value = null;
      selectedGroup.value = foundGroup;
    } else {
      $log.debug(`selectGroupById: group with ID ${groupId} not found.`);
      $log.debug('List of groups:', groups.value);
    }
  }

  function savePreviouslySelectedPersonBeforeSelectingTeamOrGroup(personId: number) {
    const foundPerson = people.value.find((person) => person.id === personId);
    if (foundPerson) {
      $log.debug('savePreviouslySelectedPersonBeforeSelectingTeamOrGroup, found person:', foundPerson.id, foundPerson.nickname);
      previouslySelectedPersonBeforeSelectingTeamOrGroup.value = foundPerson;
    } else {
      $log.debug(`savePreviouslySelectedPersonBeforeSelectingTeamOrGroup: person with ID ${personId} not found.`);
      $log.debug('List of people:', people.value);
    }
  }

  function setNextImpulseDateTime(payload: null | string) {
    nextImpulseDateTime.value = payload;
  }

  async function getLastSelectedUserAndSelectThem() {
    const ENTITY_TYPES = {
      PERSON: 1,
      GROUP: 2,
    };
    try {
      $log.debug('Will now get the selected entity.');
      const response = await $http?.get('/session/cockpit/entities/selected', { signal: undefined });
      if (response?.data && response.data.type && response.data.id) {
        $log.debug('Got the selected entity');
        switch (response.data.type) {
          case ENTITY_TYPES.PERSON:
            $log.debug('Selected entity type is PERSON');
            selectPersonById(response.data.id);
            break;
          case ENTITY_TYPES.GROUP:
            $log.debug('Selected entity type is GROUP');
            selectGroupById(response.data.id);
            break;
            // no default
        }
        if ('user_id' in response.data) {
          $log.debug('Selected entity has user_id, save it before selecting team or group');
          savePreviouslySelectedPersonBeforeSelectingTeamOrGroup(response.data.user_id);
        }
        $log.debug(`Saving selected entity type (${response.data.type}).`);
        selectedEntityType.value = response.data.type;
      } else {
        $log.debug('getLastSelectedUserAndSelectThem: check response.data content (type/id not found):', response?.data);
      }
      $log.debug('getLastSelectedUserAndSelectThem: resolving.');
    } catch (error) {
      $log.error('getLastSelectedUserAndSelectThem error:', error);
      $log.debug('Selecting own profile');
      selectPerson(ownProfile.value);
    } finally {
      // do nothing
    }
  }

  async function loadPeopleAndGroups(payload?: {
    keepPreviousImages?: boolean;
    toRefreshImages?: boolean;
    groupsOnly?: boolean;
    resetSelectedPerson?: boolean;
    ignoreCurrentPromise?: boolean;
  }) {
    const keepPreviousImages = payload && payload.keepPreviousImages === true;
    const toRefreshImages = payload && payload.toRefreshImages === true;
    const groupsOnly = payload && payload.groupsOnly === true;
    const resetSelectedPerson = payload && payload.resetSelectedPerson === true;
    const ignoreCurrentPromise = payload && payload.ignoreCurrentPromise === true;

    let fetchPeoplePromise;
    if (!groupsOnly) {
      if (!peoplePromise.value || ignoreCurrentPromise) {
        peoplePromise.value = (async () => {
          const authStore = useAuthStore();
          if (!authStore.isAuthenticated) {
            $log.debug('Not authenticated, skipping.');
            throw new Error('Trying to fetch people and attitude but is not authenticated.');
          }
          if ((!toRefreshImages && loadingPeople && !ignoreCurrentPromise)
            || (toRefreshImages && loadingPeopleToRefreshImages && !ignoreCurrentPromise)) {
            const message = 'fetchPeoplePromise: Already fetching people';
            $log.debug(message);
            throw new Error(message);
          }
          loadingPeople = true;
          increaseGlobalLoadingCounter();
          if (toRefreshImages) {
            loadingPeopleToRefreshImages = true;
          }
          try {
            const response: undefined | IndividualResponse = await $http?.get('/session/cockpit/individual', { signal: undefined });
            if (!response) {
              return undefined;
            }
            const peopleData = response.data.people
              .map((person) => ({
                ...person,
                visible: 'is_active' in person ? person.is_active : true,
                isActiveMode: true,
              }));

            individualMotivation.value = response.data.motivation || '';
            setNextImpulseDateTime(response.data.next_impulse_datetime || null);

            if (keepPreviousImages) {
              // keep current images URLs
              // $log.debug('people from server', people);
              // $log.debug('previous people', people.value);
              peopleData.forEach((person, index) => {
                peopleData[index].avatar = people.value[index].avatar;
                peopleData[index].active.chart_image = people.value[index].active.chart_image;
                peopleData[index].basic.chart_image = people.value[index].basic.chart_image;
              });
            }

            people.value = peopleData;

            // Keep previously selected person
            if (!resetSelectedPerson && selectedPerson.value) {
              await getLastSelectedUserAndSelectThem();
            }
            if (!keepPreviousImages) {
              if (resetSelectedPerson || (!selectedPerson.value && !selectedGroup.value)) {
                $log.debug('No selected person nor group, will select the last selected');
                await getLastSelectedUserAndSelectThem();
                return response;
              }
              // We already have the selected person
              return response;
            }
            return response;
          } catch (error) {
            throw new Error(`Error fetching ImpulseArea data: ${typeof error === 'object' && error && 'message' in error && error.message}`);
          } finally {
            loadingPeople = false;
            decreaseGlobalLoadingCounter();
            loadingPeopleToRefreshImages = false;
            peoplePromise.value = undefined;
          }
        })();
      } else {
        $log.debug('fetchPeoplePromise: peoplePromise.value exists; reusing the same promise.');
      }
      fetchPeoplePromise = peoplePromise.value;
    }

    const accountStore = useAccountStore();
    let fetchGroupsPromise;
    if (accountStore.userRoleIsImpTeamLeader || (isThemeV3.value && accountStore.userRoleIsImpTeamMember)) {
      if (!groupsPromise.value || ignoreCurrentPromise) {
        groupsPromise.value = (async () => {
          const authStore = useAuthStore();
          if (!authStore.isAuthenticated) {
            $log.debug('Not authenticated, skipping.');
            throw new Error('Trying to fetch people and attitude but is not authenticated.');
          }
          if (loadingGroups && !ignoreCurrentPromise) {
            const message = 'fetchGroupsPromise: Already fetching groups';
            $log.debug(message);
            throw new Error(message);
          }
          loadingGroups = true;
          try {
            const response: undefined | GroupsResponse = await $http?.get('/session/groups', { signal: undefined });
            if (!response) {
              return undefined;
            }
            const groupsData: Group[] = response.data.data
              .map((group) => ({
                ...group,
                // visible: 'is_active' in group ? group.is_active : true,
                visible: true,
                isActiveMode: true,
              }));
            $log.debug('got groups');
            $log.debug(groupsData);

            groups.value = groupsData;

            // Keep previously selected group
            if (selectedGroup.value) {
              selectGroupById(selectedGroup.value.id);
            }
            $log.debug('OK, got groups, resolving');
            return response;
          } catch (error) {
            throw new Error(`Error fetching ImpulseArea data: ${typeof error === 'object' && error && 'message' in error && error.message}`);
          } finally {
            loadingGroups = false;
            groupsPromise.value = undefined;
          }
        })();
      } else {
        $log.debug('fetchGroupsPromise: groupsPromise.value exists; reusing the same promise.');
      }
      fetchGroupsPromise = groupsPromise.value;
    }

    let promises;
    if (groupsOnly) {
      promises = [fetchGroupsPromise];
    } else {
      promises = [fetchPeoplePromise, fetchGroupsPromise];
    }
    try {
      await Promise.all(promises);
      // ignore the values
      //   $log.debug('Promise.all.then values:');
      //   $log.debug(values);
      $log.debug('got people and got groups; resolving');
      // resolve();
    } catch (error) {
      // ignore the error
      //   $log.debug('Promise.all.catch error:');
      //   $log.error(error);
      const message = 'failed getting people or getting groups; rejecting';
      $log.error(message, error);
      throw new Error(`${message}. Error: ${error}`);
    } finally {
      // resolve();
    }
  }

  function updatePeopleListButKeepTheImagesURLsToPreventLoadingThemAgain() {
    // Workaround while the service is not split into two
    return loadPeopleAndGroups({ keepPreviousImages: true });
  }

  function updatePeopleListAndFetchTheImagesURLsToo() {
    // Workaround while the service is not split into two
    return loadPeopleAndGroups({ toRefreshImages: false });
  }

  // function updateGroupsList() {
  //   return loadPeopleAndGroups({ groupsOnly: true });
  // }

  function errorFetchingImage(error: unknown) {
    const now = new Date();
    if (lastErrorFetchingImageTime && differenceInMinutes(now, lastErrorFetchingImageTime) < 1) {
      $log.debug('errorFetchingImage: happened in less than one minute; skip it this time.');
      return null;
    }
    lastErrorFetchingImageTime = now;
    $log.debug('Error fetching image; will get a new people list with updated URLs. Error:', error);
    // Get a fresh list
    return loadPeopleAndGroups({ toRefreshImages: true });
  }

  function setAnsweringQuestionnaireFor(payload: {
    firstName: string;
    lastName: string;
    target_user: {
      id: number;
    };
  }) {
    answeringQuestionnaireFor.value = payload;
  }

  function unsetAnsweringQuestionnaireFor() {
    answeringQuestionnaireFor.value = null;
  }

  function updateProfileAvatarOnPeopleList(payloadProfile: ProfileRaw) {
    if (!payloadProfile) {
      $log.error('No profile on payload.');
    }

    const userInPeople = people.value.find((p) => p.id === payloadProfile.id);
    if (!userInPeople) {
      return;
    }
    userInPeople.avatar = payloadProfile.avatar;
  }

  async function performActionUponNotification(payload: unknown) {
    if (typeof payload === 'object'
      && payload && 'data' in payload
      && typeof payload.data === 'object'
      && payload.data
      && 'category' in payload.data
      && payload.data.category === 'impulze'
      && 'role' in payload.data) {
      $log.debug('performActionUponNotification: will call changeRoleIDServerSide.');
      const accountStore = useAccountStore();
      await accountStore.changeRoleIDServerSide(payload.data.role as number);
      $log.debug('performActionUponNotification: awaited for changeRoleIDServerSide, will change route.');
      await router.push({ name: 'ImpulseArea' });
    }
  }

  function selectTheme(payload: string) {
    const foundTheme = availableThemes.value.find((theme) => theme.id === payload);
    if (foundTheme) {
      selectedTheme.value = foundTheme;
    } else {
      $log.warn('selectTheme: theme not found:', payload, availableThemes.value);
    }
  }

  function setTalentManualElementInteractionSwitchesShowAllQualityCheckMarkers(payload: boolean) {
    talentManualElementInteractionSwitches.value.showAllQualityCheckMarkers = payload;
  }

  function setTalentManualElementInteractionSwitchesShowPersonQualityCheck(payload: boolean) {
    talentManualElementInteractionSwitches.value.showPersonQualityCheck = payload;
  }

  function setTalentManualElementInteractionSwitchesShowCrossedWords(payload: boolean) {
    talentManualElementInteractionSwitches.value.showCrossedWords = payload;
  }

  function setTlyCurrentJob(payload: {}) {
    tlyCurrentJob.value = payload;
  }

  function setPreviousRoute(from: RouteLocationNormalized) {
    previousRoute.value = from;
  }

  function toggleForceHideDebugButtons() {
    forceHideDebugButtons.value = !forceHideDebugButtons.value;
  }

  async function getNumberOfActiveImpulsesForEachRole() {
    try {
      if (import.meta.env.VITE_APP_NAME !== 'imp') {
        return null;
      }
      type ActiveImpulsesResponse = {
        data: {
          pending_impulses_count: number;
          role_id: number;
          user_impulse: {
            hour: number;
            id: number;
            impulse_data: string;
            impulse_datetime: string;
            minute: number;
            status_id: number;
          }
        }[];
      };
      const response: undefined | ActiveImpulsesResponse = await $http?.get('/session/cockpit/active_impulses', { signal: undefined });
      if (!response) {
        return null;
      }
      $log.debug('got number of active impulses for each role');
      $log.debug(response.data);

      // transform the response from
      // [ { "pending_impulses_count": 1, "role_id": 4 }, { "pending_impulses_count": 0, "role_id": 6 } ]
      // into
      // { 4: { pendingImpulsesCount: 1 }, 6: { pendingImpulsesCount: 0 } }
      const transformed = response.data.reduce((acc, cur) => ({
        ...acc,
        [cur.role_id]: {
          pendingImpulsesCount: cur.pending_impulses_count,
          impulseDatetime: cur.user_impulse && cur.user_impulse.impulse_datetime,
        },
      }), {});
      numberOfActiveImpulsesForEachRole.value = transformed;
      return response;
    } catch (error) {
      throw new Error(`Error fetching number of active impulses for each role: ${typeof error === 'object' && error && 'message' in error && error.message}`);
    } finally {
      // do nothing
    }
  }

  async function fetchPersonalDevelopmentAreaDataForRole(payload: (typeof ALL_ROLES)[keyof typeof ALL_ROLES] | { id: number; }) {
    const roleId: 3 | 4 = (payload.id as 3 | 4);
    try {
      type PersonalDevelopmentAreaArchiveResponse = {
        data: PersonalDevelopmentAreaArchiveData[];
      };
      const response: undefined | PersonalDevelopmentAreaArchiveResponse = await $http?.get('/session/personal_development_area/archive', { params: { role_id: roleId }, signal: undefined });
      if (!response) {
        return;
      }
      if (response.data && Array.isArray(response.data)) {
        if (!response.data.length) {
          $log.info(`Personal development area archive (for role ${roleId}): empty result`);
        }
        personalDevelopmentAreaDataByRole.value[roleId] = response.data;
      } else {
        $log.error(`Error fetching Personal Development Area details/archive (for role ${roleId}): no response.data or response.data is not an array`);
      }
    } catch (error) {
      throw new Error(`Error fetching Personal Development Area details/archive (for role ${roleId}): ${typeof error === 'object' && error && 'message' in error && error.message}`);
    } finally {
      // do nothing
    }
  }

  // This is used in the Personal Development Area view and 'fetchAccount' action in account store
  async function getPersonalDevelopmentAreaDataForEachRole() {
    if (import.meta.env.VITE_APP_NAME !== 'imp') {
      return;
    }

    const accountStore = useAccountStore();
    const hasTeamLeaderRole = accountStore.userHasImpTeamLeaderRole;
    const hasTeamMemberRole = accountStore.userHasImpTeamMemberRole;
    if (hasTeamLeaderRole) {
      await fetchPersonalDevelopmentAreaDataForRole(ALL_ROLES.IMP_TEAM_LEADER);
    }
    if (hasTeamMemberRole) {
      await fetchPersonalDevelopmentAreaDataForRole(ALL_ROLES.IMP_TEAM_MEMBER);
    }
  }

  async function fetchLearningAndDoingCountForRole(payload: UserRole) {
    const roleId = payload.id;
    try {
      const response = await $http?.get('/session/impulses/learning-doing-count', { params: { role_id: roleId }, signal: undefined });
      if (!response) {
        return;
      }
      if (response.data && 'learning_count' in response.data && 'doing_count' in response.data) {
        learningAndDoingCountByRole.value[roleId] = response.data;
      } else {
        $log.error(`Error fetching Learning and Doing count (for role ${roleId}): no response.data or learning_count or doing_count missing:`, response.data);
      }
    } catch (error) {
      throw new Error(`Error fetching Learning and Doing count (for role ${roleId}): ${typeof error === 'object' && error && 'message' in error && error.message}`);
    } finally {
      // do nothing
    }
  }

  function setEventHeaderAnimateMenuToTeamLeaderInvitations(payload: boolean) {
    events.value.headerAnimateMenuToTeamLeaderInvitations = payload;
  }

  return {
    appName,
    isAppImp,
    isAppJgo,
    isAppTly,
    isAppZly,
    isAppZae,
    //
    // State
    //
    globalLoadingCounter,
    currentVersion,
    currentApiVersion,
    newVersionAvailable,
    showWhatsNew,
    people,
    // peoplePromise,
    individualMotivation,
    selectedEntityType,
    selectedPerson,
    groups,
    groupsPromise,
    selectedGroup,
    previouslySelectedPersonBeforeSelectingTeamOrGroup,
    temporarySelectedEntity,
    temporarySelectedClient,
    temporarySelectedClientAccount,
    answeringQuestionnaireFor,
    nextImpulseDateTime,
    receivedHelpRequestsCount,
    receivedNuggetsCount,
    nuggetsHighlights,
    availableThemes,
    selectedTheme,
    talentManualElementInteractionSwitches,
    tlyCurrentJob,
    previousRoute,
    forceHideDebugButtons,
    numberOfActiveImpulsesForEachRole,
    personalDevelopmentAreaDataByRole,
    learningAndDoingCountByRole,
    events,
    //
    // Getters
    //
    // isDevelopmentEnvironment,
    isProduction,
    showDebugButtons,
    userJourneyTalentManualPages,
    userJourneyTalentManualPagesPhase1,
    userJourneyTalentManualPagesPhase2,
    userJourneyTalentManualPagesPhase3,
    userJourneyTalentManualPagesPhase4,
    userJourneyV3Pages,
    userJourneyV3PagesPhase2,
    peopleWithProfile,
    ownProfile,
    featureToggle,
    selectedThemeId,
    isThemeV3,
    isNotThemeV3,
    isOldThemeV2,
    personalDevelopmentAreaTotalDoingPointsForRole,
    personalDevelopmentAreaTotalLearningPointsForRole,
    elementsColor,
    //
    // Actions
    //
    injectRouter,
    resetState,
    // getThemeFromEnv,
    increaseGlobalLoadingCounter,
    decreaseGlobalLoadingCounter,
    setCurrentVersion,
    setCurrentApiVersion,
    // resetShowWhatsNew,
    appComponentMounted,
    userSignedIn,
    checkVersionChangedSinceLastTime,
    selectPerson,
    setTemporarySelectedEntity,
    clearTemporarySelectedEntity,
    setTemporarySelectedClient,
    clearTemporarySelectedClient,
    setTemporarySelectedClientAccount,
    clearTemporarySelectedClientAccount,
    selectPersonById,
    selectGroupById,
    savePreviouslySelectedPersonBeforeSelectingTeamOrGroup,
    loadPeopleAndGroups,
    getLastSelectedUserAndSelectThem,
    updatePeopleListButKeepTheImagesURLsToPreventLoadingThemAgain,
    updatePeopleListAndFetchTheImagesURLsToo,
    // updateGroupsList,
    errorFetchingImage,
    setAnsweringQuestionnaireFor,
    unsetAnsweringQuestionnaireFor,
    setNextImpulseDateTime,
    setReceivedHelpRequestsCount,
    checkAlarms,
    updateProfileAvatarOnPeopleList,
    performActionUponNotification,
    selectTheme,
    setTalentManualElementInteractionSwitchesShowAllQualityCheckMarkers,
    setTalentManualElementInteractionSwitchesShowPersonQualityCheck,
    setTalentManualElementInteractionSwitchesShowCrossedWords,
    setTlyCurrentJob,
    setPreviousRoute,
    toggleForceHideDebugButtons,
    getNumberOfActiveImpulsesForEachRole,
    getPersonalDevelopmentAreaDataForEachRole,
    fetchPersonalDevelopmentAreaDataForRole,
    fetchLearningAndDoingCountForRole,
    setEventHeaderAnimateMenuToTeamLeaderInvitations,
  };
});
