<template>
  <div
    v-if="['globe-icon', 'initials', 'globe-with-initials-outside'].includes(type)"
    class="d-inline-block notranslate"
  >
    <div
      :key="`theme-${globalStore.selectedThemeId}`"
      v-tooltip="showTooltip && !showingDropdown ? translationStore.t('header.tooltipLanguage') : ''"
      :class="[component, type]"
      class="dropdown-globe-icon"
    >
      <BDropdown
        v-model="showingDropdown"
        :offset="{ alignmentAxis: 0, crossAxis: 0, mainAxis: 5 }"
        center
      >
        <!--        right-->
        <template #button-content>
          <template v-if="type === 'globe-icon'">
            <div
              class="app-icon-wrapper"
            >
              <AppIcon
                v-if="globalStore.isThemeV3"
                icon-name="language-v3"
                :show-outer-circle="false"
              />
              <AppIcon
                v-else
                icon-name="languages"
              />
            </div>
          </template>
          <template v-else-if="type === 'initials'">
            <div class="text-inside-icon">
              {{ selectedLanguage && selectedLanguage.slice(0, 2) }}
            </div>
            <svg
              class="globe-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="46"
              height="46"
              viewBox="0 0 12.358158 12.358511"
            >
              <g
                transform="translate(9.2028886,-39.844555)"
              >
                <g
                  transform="matrix(0.35277777,0,0,-0.35277777,2.8024917,46.02381)"
                >
                  <path
                    d="m 0,0 c 0,-9.122 -7.394,-16.516 -16.516,-16.516 -9.121,0 -16.515,7.394 -16.515,16.516 0,9.121 7.394,16.516 16.515,16.516 C -7.394,16.516 0,9.121 0,0"
                    style="fill:#272d33;fill-opacity:1;fill-rule:nonzero;stroke:none"
                  />
                </g>
                <g
                  transform="matrix(0.35277777,0,0,-0.35277777,2.8024917,46.02381)"
                >
                  <path
                    d="m 0,0 c 0,-9.122 -7.394,-16.516 -16.516,-16.516 -9.121,0 -16.515,7.394 -16.515,16.516 0,9.121 7.394,16.516 16.515,16.516 C -7.394,16.516 0,9.121 0,0 Z"
                    style="fill:none;stroke-width:2;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:10;stroke-dasharray:none;stroke-opacity:1"
                    class="stroke-accent-color"
                  />
                </g>
              </g>
            </svg>
          </template>
          <template v-else-if="type === 'globe-with-initials-outside'">
            <div class="app-icon-wrapper">
              <AppIcon
                :show-outer-circle="false"
                icon-name="languages"
              />
              <!--                :no-border="true"-->
              <div class="text-next-to-icon">
                {{ selectedLanguage && selectedLanguage.slice(0, 2) }}
              </div>
            </div>
          </template>
        </template>
        <BDropdownItem
          v-for="lang in availableLanguages"
          :key="lang.code"
          :active="selectedLanguage === lang.code"
          @click="clickedLanguage(lang.code)"
        >
          <span>
            {{ lang.name }}
          </span>
        </BDropdownItem>
      </BDropdown>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import { useGlobalStore } from '@/stores/global';
import { useTranslationStore } from '@/stores/translation';

import AppIcon from '@/components/misc/AppIcon.vue';

export default defineComponent({
  name: 'LanguageSelector',
  components: {
    AppIcon,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => [
        'globe-icon',
        'initials',
        'globe-with-initials-outside',
      ].includes(value),
    },
    showTooltip: {
      type: Boolean,
      default: false,
    },
    component: {
      type: String,
      required: true,
      validator: (value) => [
        'header',
        'questionnaire',
        'talent-manual',
      ].includes(value),
    },
  },
  setup() {
    return {
      globalStore: useGlobalStore(),
      translationStore: useTranslationStore(),
    };
  },
  data() {
    return {
      showingDropdown: false,
    };
  },
  computed: {
    availableLanguages() {
      if (this.component === 'header') {
        return this.translationStore.supportedLocales;
      }
      if (this.component === 'talent-manual') {
        return this.translationStore.supportedLocalesTalentManual;
      }
      if (this.component === 'questionnaire') {
        return this.translationStore.supportedLocalesQuestionnaire;
      }
      return null;
    },
    selectedLanguage() {
      if (this.component === 'header') {
        return this.translationStore.localeCode;
      }
      if (this.component === 'talent-manual') {
        return this.translationStore.localeTalentManual;
      }
      if (this.component === 'questionnaire') {
        return this.translationStore.localeQuestionnaire;
      }
      return null;
    },
  },
  methods: {
    clickedLanguage(lang) {
      this.$log.info(`LanguageSelector: user changed ${this.component} language to ${lang}`);
      if (this.component === 'header') {
        this.translationStore.changeLang(lang);
      } else if (this.component === 'talent-manual') {
        this.translationStore.changeLangTalentManual(lang);
      } else if (this.component === 'questionnaire') {
        this.translationStore.changeLangQuestionnaire(lang);
      }
    },
  },
});
</script>

<style
  lang="scss"
  scoped
>
.dropdown-globe-icon {
  position: relative;
  width: 100%;
  height: 100%;

  .dropdown {
    height: 100%;
  }

  :deep(.dropdown-toggle) {
    // Remove the padding
    padding: 0;

    &::after {
      // remove the arrow
      display: none;
    }

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    // "Hide" the button:
    background: none !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    z-index: 2;
  }

  // ↓↓↓ old bootstrap-vue-next versions (0.19.0) has .btn-group ↓↓↓
  :deep(.btn-group) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;

    .btn {
      // Hide the button
      width: 100%;
      overflow: hidden;
      //opacity: 0;
      background: none !important;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      z-index: 1;
    }
  }
  // ↑↑↑ remove this when updating to newer bootstrap-vue-next versions ↑↑↑

  &.talent-manual,
  &.questionnaire {
    width: calc(#{$header-height} / 2);
    height: calc(#{$header-height} / 2);

    .text-inside-icon {
      font-size: $font-10px;
      line-height: $font-10px;
    }
  }

  svg.globe-icon {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
  }

  .text-inside-icon {
    z-index: 1;

    position: absolute;
    top: 50%;
    left: 50%;
    cursor: pointer;
    /*width: 100%;*/
    /*height: 100%;*/
    transform: translate(-50%, -45%);

    color: $light-primary;
    font-size: $font-08px;
    line-height: $font-08px;
    letter-spacing: 0.03vw;

    text-transform: uppercase;
    font-weight: bold;

    .theme-v3e & {
      color: var(--text-color-1);
    }
  }

  .text-next-to-icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(100%, -45%);
    font-size: $font-14px;
    line-height: $font-14px;
    /*text-transform: uppercase;*/
    font-variant-caps: all-small-caps;

    .theme-v3e & {
      color: var(--text-color-1);
    }
  }
}

.app-icon-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.globe-with-initials-outside {
  .app-icon-wrapper {
    top: 0.12rem;
  }

  .app-icon {
    //top: 3px;
    width: 100%;
    height: 100%;
  }
}

//.app-icon {
//  width: calc(.5 * #{$header-height}) !important;
//  height: calc(.5 * #{$header-height}) !important;
//  position: relative;
//  top: 1px; // de-centre the icons a little bit
//}

.stroke-accent-color {
  stroke: var(--accent-color);
}
</style>
