<template>
  <BModal
    v-if="accountStore.timeZoneMismatch"
    v-model="isTimeZoneMismatchModalVisible"
    teleport-disabled
    size="sm"
    centered
    hide-footer
    static
  >
    <template #header>
      {{ translationStore.t('timeZone.modalTitle') }}
    </template>

    <template #default>
      <div class="time-zone-mismatch">
        <!--Your settings time zone ({{ accountStore.timeZoneMismatch.settings }}) does not match your current time zone.-->
        <span
          v-html="translationStore.t('timeZone.modalBody', { accountTimeZone: accountStore.timeZoneMismatch.settings, newTimeZone: accountStore.timeZoneMismatch.browser })"
        />
        <div
          v-if="globalStore.isAppImp"
          class="time-zone-mismatch-modal-secondary-text mt-gap-0_24x"
        >
          {{ translationStore.t('timeZone.modalBodyPart2') }}
        </div>

        <AppButtonWithLed
          :label="translationStore.t('timeZone.viewSettings')"
          :disabled="routeIsUserJourney"
          class="mt-gap-0_62x"
          @click="timeZoneMismatchViewSettings"
        />

        <div class="button-yes-update-settings">
          <AppButtonWithLed
            :label="translationStore.t('timeZone.updateSettings')"
            :blue="true"
            :busy="busy.savingProfile"
            class="mt-gap-0_38x"
            @click="timeZoneMismatchUpdateSettings"
          />
        </div>

        <AppButtonWithLed
          :label="translationStore.t('timeZone.skip')"
          class="mt-gap-0_38x"
          @click="timeZoneMismatchDontUpdate"
        />

        <!--All the times above are in {{ accountStore.timeZoneMismatch.settings }}.-->
        <!--Warning: your browser time zone <strong>{{ accountStore.timeZoneMismatch.browser }}</strong> does not match-->
        <!--your-->
        <!--<span-->
        <!--class="profile-settings-link"-->
        <!--@click="$router.push({ name: 'Account' })">profile settings</span>-->
        <!--<strong>{{ accountStore.timeZoneMismatch.settings }}</strong>.-->
      </div>
    </template>
  </BModal>
</template>

<script
  setup
  lang="ts"
>
import {
  computed, inject, ref, watchEffect,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { differenceInHours } from 'date-fns';

import { useAccountStore } from '@/stores/account';
import { useGlobalStore } from '@/stores/global';
import { useTranslationStore } from '@/stores/translation';
import { useToast } from '@/composables/useToast';

import AppButtonWithLed from '@/components/misc/AppButtonWithLed.vue';

const $router = useRouter();
const $route = useRoute();
const $log: any = inject('$log');
const toast = useToast();
const accountStore = useAccountStore();
const globalStore = useGlobalStore();
const translationStore = useTranslationStore();

const busy = ref({
  savingProfile: false,
});
const isTimeZoneMismatchModalVisible = ref(false);

const routeIsUserJourney = computed(() => {
  if (!$route || !$route.name) {
    return false;
  }
  return [
    'OnboardingPhase1',
    'OnboardingPhase2',
    'OnboardingPhase3',
    'OnboardingPhase4',
    'OnboardingTalentManualPhase1',
    'OnboardingTalentManualPhase2',
    'OnboardingTalentManualPhase3',
    'OnboardingTalentManualPhase4',
    'UserJourneyV3',
  ].includes($route.name.toString());
});

function gotTimezoneMismatch() {
  // Do not show the popup:
  // - when following an invitation link
  // - during user journey "complete registration"
  // - when entering the Talent Manual (there is a profile fetch made there)
  // - if it appeared less than 24 hours ago
  setTimeout(() => {
    // using setTimeout to give some time for the router to settle if the page is loading for the first time
    if (!$route.matched[0]
      || $route.matched[0].name === 'TalentManual'
      || $route.matched[0].name === 'Invitation'
      || $route.matched[0].name === 'OnboardingPhase1') {
      return;
    }
    if (accountStore.timeZoneMismatchLastShown) {
      const dismissedDate = new Date(accountStore.timeZoneMismatchLastShown);
      const now = new Date();
      const hoursAgo = differenceInHours(now, dismissedDate);
      $log.debug(`TimeZoneMismatchModal: last shown ${hoursAgo} hours ago.`);
      if (hoursAgo < 24) {
        return; // too soon to show the modal again
      }
    }
    accountStore.setTimeZoneMismatchLastShown();
    isTimeZoneMismatchModalVisible.value = true;
  }, 1000);
}

watchEffect(() => {
  $log.debug('TimeZoneMismatchModal: timeZoneMismatch is', accountStore.timeZoneMismatch);
  if (accountStore.timeZoneMismatch) {
    gotTimezoneMismatch();
  }
});

async function timeZoneMismatchUpdateSettings() {
  try {
    busy.value.savingProfile = true;
    if (accountStore.timeZoneMismatch) {
      await accountStore.saveTimeZone(accountStore.timeZoneMismatch.browser);
      toast.showToastSuccess(translationStore.t('account.profile.popupSavingTimeZoneOK'));
    }
  } catch (error) {
    toast.showToastError(translationStore.t('account.profile.popupSavingTimeZoneError'));
  } finally {
    isTimeZoneMismatchModalVisible.value = false;
    busy.value.savingProfile = false;
  }
}

function timeZoneMismatchDontUpdate() {
  isTimeZoneMismatchModalVisible.value = false;
}

function timeZoneMismatchViewSettings() {
  if (routeIsUserJourney.value) {
    // Can’t go there now
    return;
  }
  $router.push({ name: 'AccountDetails' });
  isTimeZoneMismatchModalVisible.value = false;
}
</script>

<style
  lang="scss"
  scoped
>
.time-zone-mismatch-modal-secondary-text {
  font-size: $font-14px;
}
</style>
