import i18next from 'i18next';
import I18NextVue from 'i18next-vue';
// import merge from 'lodash.merge';

// Common translations
// import messagesCommonEnGb from 'jaalgo-frontend/src/lang/en_GB.json';
// import messagesCommonDeDe from 'jaalgo-frontend/src/lang/de_DE.json';

import messagesEnGb from '@/lang/en_GB.json';
import messagesDeDe from '@/lang/de_DE.json';

import type { App } from 'vue';

// // 2022-11-09 Check all keys are the same
// const checkEntries = (previousKey, a, b) => {
//   Object.entries(a).forEach((entry) => {
//     const key = entry[0];
//     const value = entry[1];
//     const keyPath = `${previousKey}.${key}`;
//
//     if (!(key in b)) {
//       console.log(`Missing key ${keyPath}`);
//     } else {
//       // console.log(`OK, key ${keyPath} exists`);
//       // Do some checks
//       // eslint-disable-next-line no-lonely-if
//       if (Array.isArray(value)) {
//         // Array; check if length is the same
//         if (value.length !== b[key].length) {
//           console.log(`${keyPath} array has different length!`);
//         }
//       } else if (value && typeof value === 'object') {
//         // recursive
//         checkEntries(key, value, b[key]);
//       }
//     }
//   });
// };
//
// checkEntries('', messagesEnGb, messagesDeDe);
// checkEntries('', messagesDeDe, messagesEnGb);

i18next.init({
  lng: 'en-GB',
  resources: {
    'en-GB': { translation: messagesEnGb },
    'de-DE': { translation: messagesDeDe },
  },
});

const initialize = (appParam: App) => {
  appParam.use(I18NextVue, { i18next });
};

export default {
  initialize,
};
