(() => {
  console.log('xxxxx 4) DETECT INVALID BROWSER');

  /**
   * detect IE
   * returns version of IE or false, if browser is not Internet Explorer
   */
  const DIV_ID = 'detect-invalid-ie';
  const elem = document.getElementById(DIV_ID);
  if (!elem) {
    // console.log('20191127 detectInvalidBrowser: stopping.');
    return;
  }

  // console.log('20191127 detectInvalidBrowser: continuing');

  function detectIE() {
    const ua = window.navigator.userAgent;

    // Test values; Uncomment to check result …

    // IE 10
    // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

    // IE 11
    // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

    // Edge 12 (Spartan)
    // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

    // Edge 13
    // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

    // console.log('detectIE: ua =', ua);

    const msie = ua.indexOf('MSIE ');
    if (msie > 0) {
      // IE 10 or older => return version number
      const version = parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
      // console.log('detectIE: version =', version);
      return version;
    }

    const trident = ua.indexOf('Trident/');
    if (trident > 0) {
      // IE 11 => return version number
      const rv = ua.indexOf('rv:');
      const version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      // console.log('detectIE: version =', version);
      return version;
    }

    const edge = ua.indexOf('Edge/');
    if (edge > 0) {
      // Edge (IE 12+) => return version number
      const version = parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
      // console.log('detectIE: version =', version);
      return version;
    }

    // other browser
    return false;
  }

  // Get IE or Edge browser version, because if IE <= 11, stop loading the page instead of just warning of an outdated browser.
  const version = detectIE();
  let letContinue = true;

  if (version === false) {
    // Not IE/Edge, continue.
    // document.getElementById('browser-result').innerHTML = '<s>IE/Edge</s>';
  } else if (version >= 12) {
    // IE/Edge >= 12, continue.
    console.log('IE/Edge', version);
    // document.getElementById('browser-result').innerHTML = 'Edge ' + version;
  } else {
    // IE < 12 !
    letContinue = false;
  }

  function removeElementById(id: string) {
    const elemToBeDeleted = document.getElementById(id);
    if (!elemToBeDeleted) {
      console.error('No element with ID', id);
      return;
    }
    elemToBeDeleted.parentNode?.removeChild(elemToBeDeleted);
  }

  if (letContinue) {
    // Remove placeholder
    removeElementById(DIV_ID);
  } else {
    const detectInvalidIeDiv = document.getElementById(DIV_ID);
    if (detectInvalidIeDiv) {
      detectInvalidIeDiv.className += ' show';
    }
    const urlPlaceholderDiv = document.getElementById('url-placeholder');
    if (urlPlaceholderDiv) {
      urlPlaceholderDiv.innerText = window.location.toString();
    }
    // document.getElementById('url-placeholder').setAttribute('href', window.location);
    // document.getElementById('browser-result').innerHTML = 'IE ' + version;
    // document.getElementById('not-supported-message').innerHTML = '';
    removeElementById('no-api');
    removeElementById('app-preload');
    removeElementById('app');
    window.STOP_LOADING = true; // Vue will read this and will throw an Error on main.js
  }

// add details to debug result
// console.log('User Agent:', window.navigator.userAgent);
// document.getElementById('browser-details').innerHTML = window.navigator.userAgent;
})();
