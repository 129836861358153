<template>
  <BNavbar
    v-if="!hideHeader && $route.name && $route.matched[0]"
    toggleable="md"
    fixed="top"
    class="pt-0 pb-0"
  >
    <!--<BNavbarToggle target="nav_collapse"/>-->

    <BNavbarBrand
      :class="{ 'cursor-pointer': !isUserJourney }"
      @click="goToHome"
    >
      <AppLogo :for-header="true" />
    </BNavbarBrand>

    <!--<BCollapse-->
    <!--id="nav_collapse"-->
    <!--is-nav>-->

    <template v-if="(isUserJourney || isUserJourneyTalentManual) && globalStore.isNotThemeV3">
      <!-- step indicator -->
      <UserJourneyPhaseAndStepIndicators
        :is-user-journey-talent-manual="isUserJourneyTalentManual"
        :page="userJourneyPage"
        :is-for-header="true"
      />
    </template>

    <template v-if="globalStore.isAppImp && globalStore.isThemeV3">
      <BNavbarNav
        v-if="showDefaultButtons"
        class="icons-left"
      >
        <BNavItem
          class="margin-left position-relative clickable-but-hidden"
          @click.prevent="openAviationSpellingGeneratorModal"
        >
          <!-- 2023-05-06 ^^^ use @click.prevent to prevent changing the navigation (to the same route, as there is no :to prop) -->
          <!--          :to="{name: 'SpellingGenerator'}"-->
          <AppIcon
            v-tooltip="'Spell like a Pilot!'"
            icon-name="spelling"
          />
        </BNavItem>
      </BNavbarNav>
    </template>

    <!-- Right aligned nav items -->
    <BNavbarNav class="ms-auto">
      <!--<BNavForm-->
      <!--v-if="featureToggle.searchIsEnabled"-->
      <!--class="me-5"-->
      <!--&gt;-->
      <!--<BFormInput-->
      <!--size="sm"-->
      <!--class="mr-sm-2"-->
      <!--type="text"-->
      <!--placeholder="Search…"-->
      <!--@focus="searchHasFocus = true"-->
      <!--@blur="searchHasFocus = false"-->
      <!--/>-->
      <!--<BButton-->
      <!--:class="{'is-hidden': !searchHasFocus}"-->
      <!--disabled-->
      <!--size="sm"-->
      <!--class="my-2 my-sm-0"-->
      <!--type="submit"-->
      <!--&gt;-->
      <!--Search-->
      <!--</BButton>-->
      <!--</BNavForm>-->

      <div
        v-if="globalStore.newVersionAvailable || globalStore.showWhatsNew"
        class="new-version-or-updated-message-wrapper"
      >
        <Transition name="fade">
          <div
            v-if="globalStore.newVersionAvailable"
            class="new-version"
          >
            <div class="icon-placeholder">
              <AppIcon
                :no-background="true"
                :no-border="true"
                :hoverable="false"
                icon-name="info"
              />
            </div>
            <div class="content">
              <div>
                New version available: {{ globalStore.currentVersion }} → {{ globalStore.newVersionAvailable }}
              </div>
              <div
                class="action"
                @click="clickRefresh"
              >
                Reload
              </div>
            </div>
          </div>
          <!--          <div-->
          <!--            v-else-if="globalStore.showWhatsNew && globalStore.isAppImp"-->
          <!--            class="whats-new"-->
          <!--          >-->
          <!--            &lt;!&ndash; 2023-01-19: disabling this as release notes page has not been updated &ndash;&gt;-->
          <!--            <div class="icon-placeholder">-->
          <!--              <AppIcon-->
          <!--                :no-background="true"-->
          <!--                :no-border="true"-->
          <!--                :hoverable="false"-->
          <!--                icon-name="info"-->
          <!--              />-->
          <!--            </div>-->
          <!--            <div class="content">-->
          <!--              &lt;!&ndash;<div>&ndash;&gt;-->
          <!--              &lt;!&ndash;Updated.&ndash;&gt;-->
          <!--              &lt;!&ndash;</div>&ndash;&gt;-->
          <!--              <div class="d-flex justify-content-center h-100">-->
          <!--                <div-->
          <!--                  class="action"-->
          <!--                  @click="clickWhatsNew"-->
          <!--                >-->
          <!--                  What’s new?-->
          <!--                </div>-->
          <!--                <div-->
          <!--                  class="action"-->
          <!--                  @click="globalStore.resetShowWhatsNew"-->
          <!--                >-->
          <!--                  <div class="close-x">-->
          <!--                    ×-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </Transition>
      </div>

      <div
        v-if="isUserJourney && accountStore.userJourneySetupIsDemoMode"
        class="demo-mode-text"
      >
        Demo Mode
      </div>
      <div
        v-if="isUserJourney && accountStore.userJourneySetupIsReviewMode"
        class="demo-mode-text"
      >
        Review Mode
      </div>
      <template v-if="authStore.isImpersonating">
        <div
          class="exit-impersonation"
          @click="authStore.exitImpersonate"
        >
          Exit Impersonation
        </div>
      </template>

      <!--<HelpButton-->
      <!--v-if="globalStore.featureToggle.helpButton"-->
      <!--class="d-flex align-items-center mr-gap-0_38x"-->
      <!--/>-->

      <template v-if="globalStore.isThemeV3">
        <BNavbarNav
          v-if="showDefaultButtons"
          class="icons-right-first-set"
        >
          <!--      <template v-if="globalStore.isAppJgo">-->
          <!--        <template v-if="accountStore.userRoleIsJgoPartner">-->
          <!--          <BNavItem-->
          <!--            :to="{name: 'ParticipantOrPartnerWelcome'}"-->
          <!--          >-->
          <!--            <AppIcon-->
          <!--              v-tooltip="'Welcome'"-->
          <!--              :is-font-awesome="true"-->
          <!--              icon-name="home"-->
          <!--            />-->
          <!--          </BNavItem>-->
          <!--          &lt;!&ndash;          <BNavItem&ndash;&gt;-->
          <!--          &lt;!&ndash;            v-tooltip="'Management'"&ndash;&gt;-->
          <!--          &lt;!&ndash;            :to="{name: 'PartnerManagement'}"&ndash;&gt;-->
          <!--          &lt;!&ndash;            :disabled="!authStore.isAuthenticated"&ndash;&gt;-->
          <!--          &lt;!&ndash;          >&ndash;&gt;-->
          <!--          &lt;!&ndash;            <AppIcon&ndash;&gt;-->
          <!--          &lt;!&ndash;              :is-font-awesome="true"&ndash;&gt;-->
          <!--          &lt;!&ndash;              icon-name="users-cog"&ndash;&gt;-->
          <!--          &lt;!&ndash;            />&ndash;&gt;-->
          <!--          &lt;!&ndash;          </BNavItem>&ndash;&gt;-->
          <!--        </template>-->
          <!--      </template>-->

          <template v-if="(globalStore.isAppImp || globalStore.isAppZly) && accountStore.userRoleIsAdministrator">
            <BNavItem
              :to="{ name: 'Administration' }"
            >
              <AppIcon
                v-tooltip="translationStore.t('header.tooltipAdministration')"
                :is-font-awesome="false"
                :show-outer-circle="false"
                :use-v3-icon-background="true"
                icon-name="home"
              />
            </BNavItem>
          </template>

          <template v-if="accountStore.userRoleIsImpLAndDManager">
            <BNavItem
              :to="{ name: 'Dispatcher' }"
            >
              <AppIcon
                v-tooltip="translationStore.t('header.tooltipDispatcherManagement')"
                :is-font-awesome="false"
                :show-outer-circle="false"
                :use-v3-icon-background="true"
                icon-name="home"
              />
            </BNavItem>
          </template>

          <template v-if="accountStore.userRoleIsImpConsultant">
            <BNavItem
              :to="{ name: 'Consultant' }"
            >
              <AppIcon
                v-tooltip="translationStore.t('header.tooltipConsultantManagement')"
                :is-font-awesome="false"
                :show-outer-circle="false"
                :use-v3-icon-background="true"
                icon-name="home"
              />
            </BNavItem>
          </template>

          <template v-if="accountStore.userRoleIsImpTeamLeader || accountStore.userRoleIsImpTeamMember || accountStore.userRoleIsImpCoTeamLeader">
            <BNavItem
              :active="$route.matched[0].name === 'ImpulseArea'"
              :to="{ name: 'ImpulseArea' }"
              :disabled="!accountStore.userHasActiveCockpit"
            >
              <AppIcon
                v-tooltip="translationStore.t('header.tooltipImpulseArea')"
                icon-name="imp-ball"
                :show-outer-circle="false"
                :disabled="!accountStore.userHasActiveCockpit"
              />
            </BNavItem>

            <template v-if="globalStore.featureToggle.individualEmpowerment">
              <BNavItem
                :to="{ name: 'IndividualEmpowerment' }"
                :disabled="!accountStore.userHasActiveCockpit"
              >
                <AppIcon
                  v-tooltip="globalStore.isThemeV3 ? translationStore.t('header.individualEmpowerment') : translationStore.t('header.anticipationAndReflection')"
                  icon-name="imp-individual-empowerment"
                  :show-outer-circle="false"
                  :disabled="!accountStore.userHasActiveCockpit"
                />
              </BNavItem>
            </template>

            <BNavItem
              v-if="accountStore.userRoleIsImpTeamLeader || accountStore.userRoleIsImpTeamMember"
              v-tooltip="globalStore.featureToggle.personalDevelopmentArea ? translationStore.t('header.tooltipPersonalDevelopmentArea') : translationStore.t('header.tooltipPersonalDevelopmentAreaDisabled')"
              :to="{ name: 'PersonalDevelopmentArea' }"
              :disabled="!globalStore.featureToggle.personalDevelopmentArea"
            >
              <AppIcon
                icon-name="imp-personal-development-area"
                :show-outer-circle="false"
                :disabled="!globalStore.featureToggle.personalDevelopmentArea"
              />
            </BNavItem>
          </template>

          <template v-if="accountStore.userRoleIsZlyTeamLeader || accountStore.userRoleIsZlyTeamMember">
            <BNavItem
              :active="$route.matched[0].name === 'ImpulseArea'"
              :to="{ name: 'ImpulseArea' }"
              :disabled="!accountStore.userHasActiveCockpit"
            >
              <AppIcon
                v-tooltip="translationStore.t('header.tooltipImpulseArea')"
                icon-name="imp-ball"
                :show-outer-circle="false"
                :disabled="!accountStore.userHasActiveCockpit"
              />
            </BNavItem>

            <template v-if="globalStore.featureToggle.individualEmpowerment">
              <BNavItem
                :to="{ name: 'IndividualEmpowerment' }"
                :disabled="!accountStore.userHasActiveCockpit"
              >
                <AppIcon
                  v-tooltip="globalStore.isThemeV3 ? translationStore.t('header.individualEmpowerment') : translationStore.t('header.anticipationAndReflection')"
                  icon-name="imp-individual-empowerment"
                  :show-outer-circle="false"
                  :disabled="!accountStore.userHasActiveCockpit"
                />
              </BNavItem>
            </template>

            <BNavItem
              v-if="accountStore.userRoleIsZlyTeamLeader || accountStore.userRoleIsZlyTeamMember"
              v-tooltip="globalStore.featureToggle.personalDevelopmentArea ? translationStore.t('header.tooltipPersonalDevelopmentArea') : translationStore.t('header.tooltipPersonalDevelopmentAreaDisabled')"
              :to="{ name: 'PersonalDevelopmentArea' }"
              :disabled="!globalStore.featureToggle.personalDevelopmentArea"
            >
              <AppIcon
                icon-name="imp-personal-development-area"
                :show-outer-circle="false"
                :disabled="!globalStore.featureToggle.personalDevelopmentArea"
              />
            </BNavItem>
          </template>

          <!---->
          <!-- Knowledge Base: visible for all roles -->
          <!---->
          <!--          <BNavItem-->
          <!--            v-if="globalStore.isAppImp && globalStore.selectedThemeId === 'v1'"-->
          <!--            :to="{ name: 'KnowledgeBase' }"-->
          <!--            data-testid="header-button-knowledge-base"-->
          <!--          >-->
          <!--            <AppIcon-->
          <!--              v-tooltip="translationStore.t('header.tooltipKnowledgeBase')"-->
          <!--              icon-name="knowledge-base"-->
          <!--            />-->
          <!--          </BNavItem>-->
          <!--          <BNavItem-->
          <!--            v-if="globalStore.isAppImp && globalStore.featureToggle.nuggets && !accountStore.isTalentManualUser"-->
          <!--            :to="{name: 'Nuggets'}"-->
          <!--            class="margin-left position-relative"-->
          <!--          >-->
          <!--            <AppIcon-->
          <!--              v-tooltip="'Tutorial Nuggets'"-->
          <!--              icon-name="learning-nugget"-->
          <!--            />-->
          <!--            <div class="learning-nugget-icon-notification-badge">-->
          <!--              <NotificationBadge-->
          <!--                :notification-type="'nuggets'"-->
          <!--                class="badge-margin-left"-->
          <!--              />-->
          <!--            </div>-->
          <!--          </BNavItem>-->

          <!--<BNavItem href="#" disabled>Disabled</BNavItem>-->

          <template v-if="globalStore.isAppJgo && globalStore.isThemeV3">
            <!-- Old buttons -->
            <BNavItem
              v-if="accountStore.userRoleIsJgoPartner || accountStore.userRoleIsJgoParticipant"
              :to="{ name: 'ParticipantOrPartnerWelcome' }"
            >
              <AppIcon
                v-tooltip="'Old Home'"
                :is-font-awesome="false"
                icon-name="home"
              />
            </BNavItem>

            <BNavItem
              :to="{ name: 'Learning' }"
              data-testid="header-button-knowledge-base"
              class="mr-gap-3x"
            >
              <AppIcon
                v-tooltip="'Old Learning'"
                icon-name="learn-more"
              />
            </BNavItem>
          </template>

          <template v-if="globalStore.isAppJgo && globalStore.isThemeV3">
            <BNavItem
              v-if="accountStore.userRoleIsJgoPartner || accountStore.userRoleIsJgoParticipant"
              :to="{ name: 'ParticipantOrPartnerWelcomeV3' }"
            >
              <AppIcon
                v-tooltip="'Home'"
                icon-name="jgo-home-v3"
                :show-outer-circle="false"
              />
            </BNavItem>
            <BNavItem
              v-if="accountStore.userRoleIsJgoPartner || accountStore.userRoleIsJgoParticipant"
              :to="{ name: 'JgoModelArea' }"
            >
              <AppIcon
                v-tooltip="'Model Area'"
                icon-name="jgo-model-v3"
                :show-outer-circle="false"
              />
            </BNavItem>
            <BNavItem
              v-if="accountStore.userRoleIsJgoPartner || accountStore.userRoleIsJgoParticipant"
              v-tooltip="globalStore.featureToggle.personalDevelopmentArea ? translationStore.t('header.tooltipPersonalDevelopmentArea') : translationStore.t('header.tooltipPersonalDevelopmentAreaDisabled')"
              :to="{ name: 'PersonalDevelopmentArea' }"
              :disabled="!globalStore.featureToggle.personalDevelopmentArea"
            >
              <AppIcon
                icon-name="tly-personal-development-area"
                :show-outer-circle="false"
                :disabled="!globalStore.featureToggle.personalDevelopmentArea"
              />
            </BNavItem>
          </template>
          <template v-if="globalStore.isAppTly">
            <template v-if="accountStore.userRoleIsAdministrator">
              <BNavItem
                :to="{ name: 'Administration' }"
              >
                <AppIcon
                  v-tooltip="translationStore.t('header.tooltipAdministration')"
                  :is-font-awesome="false"
                  icon-name="home"
                />
              </BNavItem>
            </template>
            <!--            <BNavItem-->
            <!--              v-if="accountStore.userRoleIsTlyTeamLeader || accountStore.userHasTlyCandidateRole"-->
            <!--              :to="{ name: 'ParticipantOrPartnerWelcome' }"-->
            <!--            >-->
            <!--              <AppIcon-->
            <!--                v-tooltip="'Home (OLD, to be removed)'"-->
            <!--                :is-font-awesome="false"-->
            <!--                icon-name="home"-->
            <!--              />-->
            <!--            </BNavItem>-->
            <!--            <BNavItem-->
            <!--              v-if="accountStore.userRoleIsTlyTeamLeader || accountStore.userHasTlyCandidateRole"-->
            <!--              :to="{ name: 'Learning' }"-->
            <!--              data-testid="header-button-knowledge-base"-->
            <!--              class="mr-gap-3x"-->
            <!--            >-->
            <!--              <AppIcon-->
            <!--                v-tooltip="'Learning (OLD, to be removed)'"-->
            <!--                icon-name="learn-more"-->
            <!--              />-->
            <!--            </BNavItem>-->
            <!---->
            <!---->
            <!---->
            <BNavItem
              v-if="accountStore.userRoleIsTlyHr || accountStore.userRoleIsTlyHrAdmin || accountStore.userRoleIsTlyTeamLeader || accountStore.userRoleIsTlyTeamMember || accountStore.userHasTlyCandidateRole"
              :to="{ name: 'TreasureChest' }"
            >
              <AppIcon
                v-tooltip="translationStore.t('header.tooltipTlyTreasureChest')"
                icon-name="tly-diamond"
                :show-outer-circle="false"
              />
            </BNavItem>
            <BNavItem
              v-if="accountStore.userRoleIsTlyHr || accountStore.userRoleIsTlyHrAdmin || accountStore.userRoleIsTlyTeamLeader || accountStore.userRoleIsTlyTeamMember || accountStore.userHasTlyCandidateRole"
              :to="{ name: 'TreasureQuest' }"
              class="position-relative"
            >
              <AppIcon
                v-tooltip="treasureQuestTooltip"
                icon-name="tly-shovel"
                :show-outer-circle="false"
              />
              <div
                v-if="accountStore.userRoleIsTlyTeamLeader && treasureQuestStore.openJobs && treasureQuestStore.openJobs.length"
                class="treasure-quest-icon-notification-badge"
              >
                <NotificationBadge
                  :notification-type="'treasure-quest-open-jobs-counter'"
                  :counter="treasureQuestStore.openJobs && treasureQuestStore.openJobs.length"
                  :no-highlight="$route.matched[0].name === 'TreasureQuest'"
                  :tooltip="`There are ${treasureQuestStore.openJobs.length} open Jobs`"
                  class="badge-margin-left"
                />
              </div>
            </BNavItem>
            <BNavItem
              v-if="accountStore.userRoleIsTlyTeamLeader || accountStore.userHasTlyTeamMemberRole || accountStore.userHasTlyCandidateRole"
              v-tooltip="globalStore.featureToggle.personalDevelopmentArea ? translationStore.t('header.tooltipPersonalDevelopmentArea') : translationStore.t('header.tooltipPersonalDevelopmentAreaDisabled')"
              :to="{ name: 'PersonalDevelopmentArea' }"
              :disabled="!globalStore.featureToggle.personalDevelopmentArea"
            >
              <AppIcon
                icon-name="tly-personal-development-area"
                :show-outer-circle="false"
                :disabled="!globalStore.featureToggle.personalDevelopmentArea"
              />
            </BNavItem>
          </template>
        </BNavbarNav>
      </template>
      <BNavbarNav
        class="icons-right"
      >
        <template v-if="showDefaultButtons">
          <template v-if="globalStore.isAppJgo && globalStore.isThemeV3">
            <BNavItem
              v-if="accountStore.userRoleIsJgoPartner || accountStore.userRoleIsJgoParticipant"
              v-tooltip="'Jaagou Partner & Knowledge'"
              :to="{ name: 'PartnerAndKnowledge' }"
            >
              <AppIcon
                icon-name="jgo-partner-and-knowledge-v3"
                :show-outer-circle="false"
              />
            </BNavItem>
          </template>

          <template v-if="globalStore.isAppTly">
            <BNavItem
              v-if="accountStore.userRoleIsTlyTeamLeader || accountStore.userRoleIsTlyTeamMember || accountStore.userHasTlyCandidateRole"
              v-tooltip="globalStore.featureToggle.doersJournal ? translationStore.t('header.tooltipLogbook') : translationStore.t('header.aFutureFunction')"
              :to="{ name: 'Journal' }"
              :disabled="!globalStore.featureToggle.doersJournal"
              @click="headerEventBus.emit('click-on-journal')"
            >
              <AppIcon
                icon-name="tly-journal"
                :show-outer-circle="false"
                :disabled="!globalStore.featureToggle.doersJournal"
              />
            </BNavItem>
          </template>

          <template v-if="globalStore.isAppJgo && accountStore.userRoleIsAdministrator">
            <BNavItem
              :to="{ name: 'Administration' }"
            >
              <AppIcon
                v-tooltip="translationStore.t('header.tooltipAdministration')"
                :is-font-awesome="false"
                icon-name="home"
              />
            </BNavItem>
          </template>

          <BNavItem
            v-if="globalStore.isAppJgo && globalStore.isNotThemeV3 && (accountStore.userRoleIsJgoPartner || accountStore.userRoleIsJgoParticipant)"
            :to="{ name: 'ParticipantOrPartnerWelcome' }"
          >
            <AppIcon
              v-tooltip="'Home'"
              :is-font-awesome="false"
              icon-name="home"
            />
          </BNavItem>

          <template v-if="globalStore.isAppImp">
            <BNavItem
              v-if="globalStore.isNotThemeV3"
              class="margin-left position-relative"
              @click.prevent="openAviationSpellingGeneratorModal"
            >
              <!-- 2023-05-06 ^^^ use @click.prevent to prevent changing the navigation (to the same route, as there is no :to prop) -->
              <!--          :to="{name: 'SpellingGenerator'}"-->
              <AppIcon
                v-tooltip="'Spell like a Pilot!'"
                icon-name="spelling"
              />
            </BNavItem>
            <AviationSpellingGeneratorModal
              ref="aviationSpellingGeneratorModalComponent"
            />
          </template>

          <!---->
          <!-- Knowledge Base: visible for all roles -->
          <!---->
          <!--          <BNavItem-->
          <!--            v-if="globalStore.isAppImp && ['v2'].includes(globalStore.selectedThemeId)"-->
          <!--            v-tooltip="translationStore.t('header.tooltipKnowledgeBase')"-->
          <!--            :to="{ name: 'KnowledgeBase' }"-->
          <!--            data-testid="header-button-knowledge-base"-->
          <!--          >-->
          <!--            <AppIcon-->
          <!--              icon-name="knowledge-base"-->
          <!--              :disabled="globalStore.isThemeV3"-->
          <!--            />-->
          <!--          </BNavItem>-->

          <BNavItem
            v-if="globalStore.isAppImp || globalStore.isAppZly"
            v-tooltip="globalStore.featureToggle.doersJournal ? translationStore.t('header.tooltipLogbook') : translationStore.t('header.aFutureFunction')"
            :to="{ name: 'Journal' }"
            :disabled="!accountStore.userHasActiveCockpit || !globalStore.featureToggle.doersJournal"
            @click="headerEventBus.emit('click-on-journal')"
          >
            <!--            v-tooltip="globalStore.isThemeV3 ? translationStore.t('header.tooltipLogbookV3') : translationStore.t('header.tooltipLogbook')"-->
            <AppIcon
              icon-name="imp-journal"
              :show-outer-circle="globalStore.isNotThemeV3"
              :disabled="!accountStore.userHasActiveCockpit || !globalStore.featureToggle.doersJournal"
            />
          </BNavItem>

          <BNavItem
            v-if="globalStore.isAppJgo && globalStore.isNotThemeV3"
            :to="{ name: 'Learning' }"
            data-testid="header-button-knowledge-base"
          >
            <AppIcon
              v-tooltip="'Learning'"
              icon-name="learn-more"
            />
          </BNavItem>

          <!--          <BNavItem-->
          <!--            v-if="globalStore.selectedThemeId === 'v1' && globalStore.featureToggle.nuggets"-->
          <!--            :to="{ name: 'Nuggets' }"-->
          <!--            class="margin-left position-relative"-->
          <!--          >-->
          <!--            <AppIcon-->
          <!--              v-tooltip="'Tutorial Nuggets'"-->
          <!--              icon-name="learning-nugget"-->
          <!--            />-->
          <!--            <div class="learning-nugget-icon-notification-badge">-->
          <!--              <NotificationBadge-->
          <!--                class="badge-margin-left"-->
          <!--                :notification-type="'nuggets'"-->
          <!--              />-->
          <!--            </div>-->
          <!--          </BNavItem>-->
        </template>

        <BNavItem>
          <LanguageSelector
            type="globe-icon"
            show-tooltip
            component="header"
            class="language-selector"
            @click.prevent
          />
          <!-- 2023-05-06 ^^^ use @click.prevent to prevent changing the navigation (to the same route, as there is no :to prop) -->
        </BNavItem>

        <template v-if="!isUserJourney">
          <BNavItem
            v-if="accountStore.userHasTalentManual"
            :disabled="!accountStore.userHasTalentManual || !accountStore.userCanAccessTalentManual"
            :active="$route.matched[0].name === 'TalentManual'"
            @click.prevent="goToOwnTalentManual"
          >
            <!--          :to="{name: 'TalentManual'}"-->
            <AppIcon
              v-if="globalStore.isAppImp || globalStore.isAppZly"
              v-tooltip="translationStore.t('header.tooltipTalentManualV3')"
              icon-name="imp-talent-manual"
              :show-outer-circle="false"
              :disabled="!accountStore.userHasTalentManual || !accountStore.userCanAccessTalentManual"
            />
            <AppIcon
              v-else-if="globalStore.isAppTly"
              v-tooltip="translationStore.t('header.tooltipTalentManualV3')"
              icon-name="tly-talent-manual"
              :show-outer-circle="false"
              :disabled="!accountStore.userHasTalentManual || !accountStore.userCanAccessTalentManual"
            />
            <AppIcon
              v-else-if="globalStore.isAppJgo && globalStore.isThemeV3"
              v-tooltip="translationStore.t('header.tooltipTalentManualV3')"
              icon-name="jgo-talent-manual-v3"
              :show-outer-circle="false"
              :disabled="!accountStore.userHasTalentManual || !accountStore.userCanAccessTalentManual"
            />
            <AppIcon
              v-else
              v-tooltip="globalStore.isThemeV3 ? translationStore.t('header.tooltipTalentManualV3') : translationStore.t('header.tooltipTalentManual')"
              icon-name="talent-manual-icon"
              :disabled="!accountStore.userHasTalentManual || !accountStore.userCanAccessTalentManual"
            />
          </BNavItem>
        </template>
      </BNavbarNav>

      <!--<div-->
      <!--v-if="!hideAvatar && accountStore.userRoles.length > 1"-->
      <!--class="dropdown-icon-wrapper-outer"-->
      <!--&gt;-->
      <!--<div-->
      <!--class="dropdown-icon-wrapper"-->
      <!--&gt;-->
      <!--<BDropdown-->
      <!--right-->
      <!--class="header-name-dropdown"-->
      <!--no-caret-->
      <!--&gt;-->
      <!--&lt;!&ndash;<BDropdownDivider v-if="!isUserJourney && !accountStore.userRoleIsImpConsultant" />&ndash;&gt;-->
      <!--<BDropdownItem-->
      <!--v-for="role in userRolesIdAndCode"-->
      <!--:key="`role-${role.code}`"-->
      <!--:disabled="accountStore.userRoleCode === role.code"-->
      <!--:active="accountStore.userRoleCode === role.code"-->
      <!--@click="selectedRoleFromUserMenu(role.id)"-->
      <!--&gt;-->
      <!--{{ roleName(role.code) }}-->
      <!--</BDropdownItem>-->
      <!--</BDropdown>-->

      <!--<div-->
      <!--class="badge-container at-icon-dropdown"-->
      <!--&gt;-->
      <!--&lt;!&ndash;v-tooltip="tooltipForEntityBadgeCurrentRole"&ndash;&gt;-->
      <!--<EntityBadge-->
      <!--:entity-type="entityTypeForBadgeCurrentRole"-->
      <!--class="entity-badge"-->
      <!--/>-->
      <!--</div>-->
      <!--</div>-->
      <!--</div>-->

      <div
        v-if="!hideAvatar"
        class="avatar-and-name-dropdown"
      >
        <!--        <div-->
        <!--          v-if="globalStore.featureToggle.helpButton && !accountStore.userRoleIsAdministrator"-->
        <!--          class="demo-sos-switch-hotspot"-->
        <!--          @click="sosIsOn = !sosIsOn"-->
        <!--        />-->
        <!--        <div-->
        <!--          class="demo-alert-switch-hotspot"-->
        <!--          @click="alertIsOn = !alertIsOn"-->
        <!--        />-->
        <BNavItemDropdown
          v-model="isMainMenuShown"
          right
          class="header-name-dropdown"
          no-caret
          @shown="mainMenuShown"
          @hidden="mainMenuHidden"
        >
          <!-- Using button-content slot -->
          <template #button-content>
            <div class="avatar-wrapper header">
              <!--              <div-->
              <!--                v-if="globalStore.featureToggle.nuggets && alertIsOn"-->
              <!--                class="alert-badge1 badge1 _ngcontent-jmc-29"-->
              <!--              />-->
              <template v-if="authStore.isAuthenticated">
                <AppAvatar
                  :entity="userProfileWithActiveRowsForAvatar || undefined"
                  :hide-background="true"
                  @avatar-image-error="onAvatarImageError"
                />
              </template>
              <template v-else-if="isUserJourney && accountStore.invitationInfo?.firstName">
                <AppAvatar
                  :entity="{ first_name: accountStore.invitationInfo.firstName, last_name: accountStore.invitationInfo.lastName }"
                  :hide-background="true"
                />
              </template>
              <div
                v-if="globalStore.featureToggle.helpButton && (accountStore.userHasImpTeamLeaderRole || accountStore.userHasImpCoTeamLeaderRole || accountStore.userHasImpTeamMemberRole) && sosIsOn"
                class="avatar-help-overlay"
              >
                <img
                  class="opacity1"
                  src="@/components/help-button/assets/help_button.png"
                >
                <!--                <img-->
                <!--                  :class="{pressed: helpButtonIsPressed}"-->
                <!--                  src="@/components/help-button/assets/help_button_pressed.png"-->
                <!--                >-->
              </div>
              <div
                v-if="!sosIsOn"
                class="avatar-notification-overlay"
              >
                <NotificationBadge
                  :notification-type="'active-impulse'"
                  :counter="totalNumberOfOpenImpulses"
                  class="badge-margin-left"
                />
              </div>
            </div>
          </template>

          <template v-if="authStore.isAuthenticated && accountStore.userProfile">
            <BDropdownText
              @click.stop
            >
              <div>
                <div class="menu-nickname">
                  {{ accountStore.userProfile?.nickname }}
                </div>
                <div class="menu-username">
                  {{ accountStore.userProfile?.email }}
                </div>
              </div>
            </BDropdownText>
          </template>
          <template v-else-if="isUserJourney && accountStore.invitationInfo?.firstName">
            <BDropdownText
              v-tooltip="'Invitation info'"
              @click.stop
            >
              <div>
                <div class="menu-nickname">
                  {{ accountStore.invitationInfo?.firstName }}
                </div>
                <div class="menu-username">
                  {{ accountStore.invitationInfo?.email }}
                </div>
              </div>
            </BDropdownText>
          </template>

          <BDropdownDivider
            v-if="!accountStore.userRoleIsNone"
          />

          <BDropdownItem
            v-tooltip="isUserJourney ? tooltipItemDisabledIfUserJourney : ''"
            :to="{ name: 'Account' }"
            :disabled="isUserJourney"
          >
            {{ translationStore.t('account.pageTitle') }} <!-- Account Settings -->
          </BDropdownItem>

          <BDropdownDivider
            v-if="authStore.isAuthenticated && accountStore.userProfile && !accountStore.userRoleIsNone"
          />

          <template v-if="!isUserJourney">
            <!--<BDropdownItem-->
            <!--v-if="accountStore.userRoleIsImpTeamLeader || accountStore.userRoleIsImpTeamMember || accountStore.userRoleIsImpCoTeamLeader"-->
            <!--:disabled="!accountStore.userHasTalentManual"-->
            <!--@click="redirectToProfile"-->
            <!--&gt;-->
            <!--{{ translationStore.t('header.buttonTalentManual') }}-->
            <!--</BDropdownItem>-->

            <BDropdownItem
              v-if="globalStore.featureToggle.helpButton && (accountStore.userHasImpTeamLeaderRole || accountStore.userHasImpCoTeamLeaderRole || accountStore.userHasImpTeamMemberRole)"
              :to="{ name: 'ReceivedHelpRequests' }"
              @click="sosIsOn = false"
            >
              <!--{{ translationStore.t('header.buttonTalentManual') }}-->
              <div
                class="d-flex align-items-center"
              >
                <div>
                  Help requests
                </div>
                <NotificationBadge
                  :notification-type="'help-requests'"
                  class="badge-margin-left"
                />
              </div>
            </BDropdownItem>

            <!--            <BDropdownItem-->
            <!--              v-if="globalStore.featureToggle.nuggets"-->
            <!--              :to="{name: 'Nuggets'}"-->
            <!--            >-->
            <!--              &lt;!&ndash;              @click="sosIsOn = false"&ndash;&gt;-->
            <!--              &lt;!&ndash;{{ translationStore.t('header.buttonTalentManual') }}&ndash;&gt;-->
            <!--              <div-->
            <!--                class="d-flex align-items-center"-->
            <!--                @click="$refs.mainMenu.hide(true)"-->
            <!--              >-->
            <!--                <div>-->
            <!--                  Tutorial Nuggets-->
            <!--                </div>-->
            <!--                <NotificationBadge :notification-type="'nuggets'" />-->
            <!--              </div>-->
            <!--            </BDropdownItem>-->
            <BDropdownDivider
              v-if="globalStore.featureToggle.helpButton && (accountStore.userHasImpTeamLeaderRole || accountStore.userHasImpCoTeamLeaderRole || accountStore.userHasImpTeamMemberRole)"
            />

            <!--<BDropdownItem--> <!-- MOVED TO SUB-MENU -->
            <!--v-if="accountStore.userRoleIsImpLAndDManager || accountStore.userRoleIsImpTeamLeader"-->
            <!--:to="{name: 'Invitations'}"-->
            <!--class="d-flex"-->
            <!--&gt;-->
            <!--&lt;!&ndash;<div class="badge-container">&ndash;&gt;-->
            <!--&lt;!&ndash;<EntityBadge&ndash;&gt;-->
            <!--&lt;!&ndash;v-tooltip="'Dispatcher'"&ndash;&gt;-->
            <!--&lt;!&ndash;v-if="accountStore.userRoleIsImpLAndDManager"&ndash;&gt;-->
            <!--&lt;!&ndash;entity-type="dispatcher"&ndash;&gt;-->
            <!--&lt;!&ndash;class="entity-badge"&ndash;&gt;-->
            <!--&lt;!&ndash;/>&ndash;&gt;-->
            <!--&lt;!&ndash;<EntityBadge&ndash;&gt;-->
            <!--&lt;!&ndash;v-tooltip="'Pilot'"&ndash;&gt;-->
            <!--&lt;!&ndash;v-if="accountStore.userRoleIsImpTeamLeader"&ndash;&gt;-->
            <!--&lt;!&ndash;entity-type="pilot"&ndash;&gt;-->
            <!--&lt;!&ndash;class="entity-badge"&ndash;&gt;-->
            <!--&lt;!&ndash;/>&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--{{ translationStore.t(`header.buttonInvitations.${accountStore.userRoleCode}`) }}-->
            <!--</BDropdownItem>-->

            <!--<BDropdownItem--> <!-- MOVED TO SUB-MENU -->
            <!--v-if="accountStore.userRoleIsAdministrator || accountStore.userRoleIsImpLAndDManager || accountStore.userRoleIsImpTeamLeader"-->
            <!--:to="{name: 'ConfigureEmailTemplate'}"-->
            <!--class="d-flex"-->
            <!--&gt;-->
            <!--&lt;!&ndash;<div class="badge-container">&ndash;&gt;-->
            <!--&lt;!&ndash;<EntityBadge&ndash;&gt;-->
            <!--&lt;!&ndash;v-tooltip="'Administrator'"&ndash;&gt;-->
            <!--&lt;!&ndash;v-if="accountStore.userRoleIsAdministrator"&ndash;&gt;-->
            <!--&lt;!&ndash;entity-type="dispatcher"&ndash;&gt;-->
            <!--&lt;!&ndash;class="entity-badge"&ndash;&gt;-->
            <!--&lt;!&ndash;/>&ndash;&gt;-->
            <!--&lt;!&ndash;<EntityBadge&ndash;&gt;-->
            <!--&lt;!&ndash;v-tooltip="'Dispatcher'"&ndash;&gt;-->
            <!--&lt;!&ndash;v-if="accountStore.userRoleIsImpLAndDManager"&ndash;&gt;-->
            <!--&lt;!&ndash;entity-type="dispatcher"&ndash;&gt;-->
            <!--&lt;!&ndash;class="entity-badge"&ndash;&gt;-->
            <!--&lt;!&ndash;/>&ndash;&gt;-->
            <!--&lt;!&ndash;<EntityBadge&ndash;&gt;-->
            <!--&lt;!&ndash;v-tooltip="'Pilot'"&ndash;&gt;-->
            <!--&lt;!&ndash;v-if="accountStore.userRoleIsImpTeamLeader"&ndash;&gt;-->
            <!--&lt;!&ndash;entity-type="pilot"&ndash;&gt;-->
            <!--&lt;!&ndash;class="entity-badge"&ndash;&gt;-->
            <!--&lt;!&ndash;/>&ndash;&gt;-->
            <!--&lt;!&ndash;</div>&ndash;&gt;-->
            <!--Your invitation message (email)-->
            <!--</BDropdownItem>-->

            <!--<BDropdownItem-->  <!-- MOVED TO SUB-MENU -->
            <!--v-if="accountStore.userRoleIsImpTeamLeader && globalStore.featureToggle.coPilot"-->
            <!--:to="{name: 'CoPilot'}"-->
            <!--class="d-flex"-->
            <!--&gt;-->
            <!--Co-Pilot management-->
            <!--</BDropdownItem>-->

            <!-- Access Control moved to inside the profile settings: -->
            <!--<BDropdownItem-->
            <!--v-if="(accountStore.userRoleIsImpTeamLeader || accountStore.userRoleIsImpTeamMember) && globalStore.featureToggle.accessControl"-->
            <!--:to="{name: 'AccountAccessControl'}"-->
            <!--class="d-flex"-->
            <!--&gt;-->
            <!--Access Control-->
            <!--</BDropdownItem>-->

            <!--<BDropdownItem-->
            <!--v-if="accountStore.userRoleIsAdministrator"-->
            <!--:to="{name: 'Administration'}"-->
            <!--&gt;-->
            <!--Administration-->
            <!--</BDropdownItem>-->
          </template>

          <!---->
          <!-- Sub-menu for each role -->
          <!---->

          <!---->
          <!-- Admin -->
          <!---->
          <div
            v-if="accountStore.userHasAdministratorRole"
            v-tooltip="isUserJourney ? tooltipItemDisabledIfUserJourney : ''"
          >
            <li
              :class="{ 'active-role': accountStore.userRoleIsAdministrator, disabled: isUserJourney }"
              class="dropdown-item dropdown-submenu"
              @mouseenter="openSubmenu"
              @click="selectedRoleFromUserMenu(accountStore.allRoles.ADMIN.id)"
            >
              {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.ADMIN.id}`) }} <!-- Administrator -->
              <ul class="dropdown-menu left">
                <BDropdownItem
                  :active="$route.matched[0].name === 'Administration' && accountStore.userRoleIsAdministrator"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.ADMIN, 'Administration')"
                >
                  Administration
                </BDropdownItem>
                <BDropdownItem
                  v-if="globalStore.isAppImp || globalStore.isAppZly"
                  :active="$route.name === 'ConfigureEmailTemplate' && accountStore.userRoleIsAdministrator"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.ADMIN, 'ConfigureEmailTemplate')"
                >
                  Your invitation message (email)
                </BDropdownItem>
                <!--<BDropdownItem>-->
                <!--Item 3-->
                <!--</BDropdownItem>-->
              </ul>
            </li>
          </div>
          <!---->
          <!-- IMP Consultant -->
          <!---->
          <div
            v-if="accountStore.userHasImpConsultantRole"
            v-tooltip="isUserJourney ? tooltipItemDisabledIfUserJourney : ''"
          >
            <li
              :class="{ 'active-role': accountStore.userRoleIsImpConsultant, disabled: isUserJourney }"
              class="dropdown-item dropdown-submenu"
              @mouseenter="openSubmenu"
              @click="selectedRoleFromUserMenu(accountStore.allRoles.IMP_CONSULTANT.id)"
            >
              {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.IMP_CONSULTANT.id}`) }} <!-- Consultant-->
              <ul class="dropdown-menu left">
                <BDropdownItem
                  :active="$route.name === 'ConsultantAccounts' && accountStore.userRoleIsImpConsultant"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.IMP_CONSULTANT, 'ConsultantAccounts')"
                >
                  Evaluation accounts
                </BDropdownItem>
                <BDropdownItem
                  v-if="globalStore.featureToggle.invitationsForTalentManual"
                  :active="$route.name === 'ConsultantTalentManualInvitations' && accountStore.userRoleIsImpConsultant"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.IMP_CONSULTANT, 'ConsultantTalentManualInvitations')"
                >
                  Talent Manual invitations
                </BDropdownItem>
                <BDropdownItem
                  :active="$route.name === 'ConfigureEmailTemplate' && accountStore.userRoleIsImpConsultant"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.IMP_CONSULTANT, 'ConfigureEmailTemplate')"
                >
                  Your invitation message (email)
                </BDropdownItem>
              </ul>
            </li>
          </div>
          <!---->
          <!-- IMP L&D Manager -->
          <!---->
          <div
            v-if="accountStore.userHasImpLAndDManagerRole"
            v-tooltip="isUserJourney ? tooltipItemDisabledIfUserJourney : ''"
          >
            <li
              :class="{ 'active-role': accountStore.userRoleIsImpLAndDManager, disabled: isUserJourney }"
              class="dropdown-item dropdown-submenu"
              @mouseenter="openSubmenu"
              @click="selectedRoleFromUserMenu(accountStore.allRoles.IMP_L_AND_D_MANAGER.id)"
            >
              {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.IMP_L_AND_D_MANAGER.id}`) }} <!-- L&D Manager -->
              <ul class="dropdown-menu left">
                <BDropdownItem
                  :active="$route.name === 'DispatcherAccounts' && accountStore.userRoleIsImpLAndDManager"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.IMP_L_AND_D_MANAGER, 'DispatcherAccounts')"
                >
                  Accounts
                </BDropdownItem>
                <BDropdownItem
                  :active="$route.name === 'Invitations' && accountStore.userRoleIsImpLAndDManager"
                  class="d-flex"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.IMP_L_AND_D_MANAGER, 'Invitations')"
                >
                  <!--<div class="badge-container">-->
                  <!--<EntityBadge-->
                  <!--v-tooltip="'Dispatcher'"-->
                  <!--v-if="accountStore.userRoleIsImpLAndDManager"-->
                  <!--entity-type="dispatcher"-->
                  <!--class="entity-badge"-->
                  <!--/>-->
                  <!--<EntityBadge-->
                  <!--v-tooltip="'Pilot'"-->
                  <!--v-if="accountStore.userRoleIsImpTeamLeader"-->
                  <!--entity-type="pilot"-->
                  <!--class="entity-badge"-->
                  <!--/>-->
                  <!--</div>-->
                  {{ translationStore.t(`header.buttonInvitations.${accountStore.allRoles.IMP_L_AND_D_MANAGER.code}`) }}
                </BDropdownItem>
                <BDropdownItem
                  :active="$route.name === 'ConfigureEmailTemplate' && accountStore.userRoleIsImpLAndDManager"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.IMP_L_AND_D_MANAGER, 'ConfigureEmailTemplate')"
                >
                  Your invitation message (email)
                </BDropdownItem>
                <BDropdownItem
                  v-if="globalStore.featureToggle.coPilot"
                  :active="$route.name === 'DispatcherCoPilotRequests' && accountStore.userRoleIsImpLAndDManager"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.IMP_L_AND_D_MANAGER, 'DispatcherCoPilotRequests')"
                >
                  {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.IMP_CO_TEAM_LEADER.id}`) }} requests
                </BDropdownItem>
              </ul>
            </li>
          </div>
          <!---->
          <!-- TLY HR -->
          <!---->
          <div
            v-if="accountStore.userHasTlyHrRole"
            v-tooltip="isUserJourney ? tooltipItemDisabledIfUserJourney : ''"
          >
            <li
              :class="{ 'active-role': accountStore.userRoleIsTlyHr, disabled: isUserJourney }"
              class="dropdown-item dropdown-submenu"
              @mouseenter="openSubmenu"
              @click="selectedRoleFromUserMenu(accountStore.allRoles.TLY_HR.id)"
            >
              {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.TLY_HR.id}`) }} <!-- HR -->
              <ul class="dropdown-menu left">
                <!--                <BDropdownItem-->
                <!--                  :active="$route.name === 'ParticipantOrPartnerWelcome' && accountStore.userRoleIsTlyHr"-->
                <!--                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.TLY_HR, 'ParticipantOrPartnerWelcome')"-->
                <!--                >-->
                <!--                  Welcome-->
                <!--                </BDropdownItem>-->
                <BDropdownItem
                  :active="$route.matched[0].name === 'TreasureChest' && accountStore.userRoleIsTlyHr"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.TLY_HR, 'TreasureChest')"
                >
                  {{ translationStore.t('tlyTreasureChest.titleForHeader') }}
                </BDropdownItem>
              </ul>
            </li>
          </div>
          <!---->
          <!-- TLY HR Admin -->
          <!---->
          <div
            v-if="accountStore.userHasTlyHrAdminRole"
            v-tooltip="isUserJourney ? tooltipItemDisabledIfUserJourney : ''"
          >
            <li
              :class="{ 'active-role': accountStore.userRoleIsTlyHrAdmin, disabled: isUserJourney }"
              class="dropdown-item dropdown-submenu"
              @mouseenter="openSubmenu"
              @click="selectedRoleFromUserMenu(accountStore.allRoles.TLY_HR_ADMIN.id)"
            >
              {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.TLY_HR_ADMIN.id}`) }} <!-- HR Admin -->
              <ul class="dropdown-menu left">
                <!--                <BDropdownItem-->
                <!--                  :active="$route.name === 'ParticipantOrPartnerWelcome' && accountStore.userRoleIsTlyHrAdmin"-->
                <!--                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.TLY_HR_ADMIN, 'ParticipantOrPartnerWelcome')"-->
                <!--                >-->
                <!--                  Welcome-->
                <!--                </BDropdownItem>-->
                <BDropdownItem
                  :active="$route.matched[0].name === 'TreasureChest' && accountStore.userRoleIsTlyHrAdmin"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.TLY_HR_ADMIN, 'TreasureChest')"
                >
                  {{ translationStore.t('tlyTreasureChest.titleForHeader') }}
                </BDropdownItem>
              </ul>
            </li>
          </div>
          <!---->
          <!-- IMP Team Leader -->
          <!---->
          <div
            v-if="accountStore.userHasImpTeamLeaderRole"
            v-tooltip="isUserJourney ? tooltipItemDisabledIfUserJourney : ''"
          >
            <li
              :class="{ 'active-role': accountStore.userRoleIsImpTeamLeader, disabled: isUserJourney }"
              class="dropdown-item dropdown-submenu"
              @mouseenter="openSubmenu"
              @click="selectedRoleFromUserMenu(accountStore.allRoles.IMP_TEAM_LEADER.id)"
            >
              {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.IMP_TEAM_LEADER.id}`) }} <!-- Team Leader -->
              <NotificationBadge
                :notification-type="'active-impulse'"
                :counter="globalStore.numberOfActiveImpulsesForEachRole[accountStore.allRoles.IMP_TEAM_LEADER.id] && globalStore.numberOfActiveImpulsesForEachRole[accountStore.allRoles.IMP_TEAM_LEADER.id].pendingImpulsesCount"
                class="badge-margin-left"
              />
              <ul class="dropdown-menu left">
                <BDropdownItem
                  :active="$route.name === 'Invitations' && accountStore.userRoleIsImpTeamLeader"
                  class="d-flex"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.IMP_TEAM_LEADER, 'Invitations')"
                >
                  <!--<div class="badge-container">-->
                  <!--<EntityBadge-->
                  <!--v-tooltip="'Dispatcher'"-->
                  <!--v-if="accountStore.userRoleIsImpLAndDManager"-->
                  <!--entity-type="dispatcher"-->
                  <!--class="entity-badge"-->
                  <!--/>-->
                  <!--<EntityBadge-->
                  <!--v-tooltip="'Pilot'"-->
                  <!--v-if="accountStore.userRoleIsImpTeamLeader"-->
                  <!--entity-type="pilot"-->
                  <!--class="entity-badge"-->
                  <!--/>-->
                  <!--</div>-->
                  {{ translationStore.t(`header.buttonInvitations.${accountStore.allRoles.IMP_TEAM_LEADER.code}`) }}
                </BDropdownItem>
                <BDropdownItem
                  :active="$route.name === 'ConfigureEmailTemplate' && accountStore.userRoleIsImpTeamLeader"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.IMP_TEAM_LEADER, 'ConfigureEmailTemplate')"
                >
                  Your invitation message (email)
                </BDropdownItem>
                <BDropdownItem
                  v-if="globalStore.featureToggle.coPilot"
                  :active="$route.matched[0].name === 'CoPilot' && accountStore.userRoleIsImpTeamLeader"
                  :link-class="'d-flex'"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.IMP_TEAM_LEADER, 'CoPilot')"
                >
                  {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.IMP_CO_TEAM_LEADER.id}`) }} management
                </BDropdownItem>
                <!--<BDropdownItem>-->
                <!--Item 3-->
                <!--</BDropdownItem>-->
              </ul>
            </li>
          </div>
          <!---->
          <!-- IMP Co-Team Leader -->
          <!---->
          <div
            v-if="accountStore.userHasImpCoTeamLeaderRole"
            v-tooltip="isUserJourney ? tooltipItemDisabledIfUserJourney : ''"
          >
            <li
              :class="{ 'active-role': accountStore.userRoleIsImpCoTeamLeader, disabled: isUserJourney }"
              class="dropdown-item dropdown-submenu"
              @mouseenter="openSubmenu"
              @click="selectedRoleFromUserMenu(accountStore.allRoles.IMP_CO_TEAM_LEADER.id)"
            >
              {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.IMP_CO_TEAM_LEADER.id}`) }} <!-- Co-Team Leader-->
              <NotificationBadge
                :notification-type="'active-impulse'"
                :counter="globalStore.numberOfActiveImpulsesForEachRole[accountStore.allRoles.IMP_CO_TEAM_LEADER.id] && globalStore.numberOfActiveImpulsesForEachRole[accountStore.allRoles.IMP_CO_TEAM_LEADER.id].pendingImpulsesCount"
                class="badge-margin-left"
              />
              <ul
                v-if="accountStore.coPilotPilots && accountStore.coPilotPilots.length"
                class="dropdown-menu left"
              >
                <BDropdownItem
                  v-for="pilot in accountStore.coPilotPilots"
                  :key="`co-pilot-pilot-${pilot.id}`"
                  :active="Boolean(accountStore.selectedCoPilotPilot && accountStore.selectedCoPilotPilot.id === pilot.id)"
                  @click.stop="selectCoPilotPilotAndChangeRole(accountStore.allRoles.IMP_CO_TEAM_LEADER, pilot)"
                >
                  <!--                Team Leader: {{ pilot.nickname }}-->
                  {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.IMP_TEAM_LEADER.id}`) }}: {{ pilot.nickname }}
                </BDropdownItem>
                <!--<BDropdownItem>-->
                <!--Item 2-->
                <!--</BDropdownItem>-->
                <!--<BDropdownItem>-->
                <!--Item 3-->
                <!--</BDropdownItem>-->
              </ul>
            </li>
          </div>
          <!---->
          <!-- IMP Team Member -->
          <!---->
          <div
            v-if="accountStore.userHasImpTeamMemberRole"
            v-tooltip="isUserJourney ? tooltipItemDisabledIfUserJourney : ''"
          >
            <li
              :class="{ 'active-role': accountStore.userRoleIsImpTeamMember, disabled: isUserJourney }"
              class="dropdown-item dropdown-submenu"
              @mouseenter="openSubmenu"
              @click="selectedRoleFromUserMenu(accountStore.allRoles.IMP_TEAM_MEMBER.id)"
            >
              {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.IMP_TEAM_MEMBER.id}`) }} <!-- Team Member-->
              <NotificationBadge
                :notification-type="'active-impulse'"
                :counter="globalStore.numberOfActiveImpulsesForEachRole[accountStore.allRoles.IMP_TEAM_MEMBER.id] && globalStore.numberOfActiveImpulsesForEachRole[accountStore.allRoles.IMP_TEAM_MEMBER.id].pendingImpulsesCount"
                class="badge-margin-left"
              />
              <!--<ul class="dropdown-menu left">-->
              <!--<BDropdownItem>-->
              <!--Item 1-->
              <!--</BDropdownItem>-->
              <!--<BDropdownItem>-->
              <!--Item 2-->
              <!--</BDropdownItem>-->
              <!--<BDropdownItem>-->
              <!--Item 3-->
              <!--</BDropdownItem>-->
              <!--</ul>-->
            </li>
          </div>
          <!---->
          <!-- ZLY Team Leader -->
          <!---->
          <div
            v-if="accountStore.userHasZlyTeamLeaderRole"
            v-tooltip="isUserJourney ? tooltipItemDisabledIfUserJourney : ''"
          >
            <li
              :class="{ 'active-role': accountStore.userRoleIsZlyTeamLeader, disabled: isUserJourney }"
              class="dropdown-item dropdown-submenu"
              @mouseenter="openSubmenu"
              @click="selectedRoleFromUserMenu(accountStore.allRoles.ZLY_TEAM_LEADER.id)"
            >
              {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.ZLY_TEAM_LEADER.id}`) }} <!-- Team Leader -->
              <NotificationBadge
                :notification-type="'active-impulse'"
                :counter="globalStore.numberOfActiveImpulsesForEachRole[accountStore.allRoles.ZLY_TEAM_LEADER.id] && globalStore.numberOfActiveImpulsesForEachRole[accountStore.allRoles.ZLY_TEAM_LEADER.id].pendingImpulsesCount"
                class="badge-margin-left"
              />
              <ul class="dropdown-menu left">
                <BDropdownItem
                  :active="$route.name === 'Invitations' && accountStore.userRoleIsImpTeamLeader"
                  class="d-flex"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.ZLY_TEAM_LEADER, 'Invitations')"
                >
                  <!--<div class="badge-container">-->
                  <!--<EntityBadge-->
                  <!--v-tooltip="'Dispatcher'"-->
                  <!--v-if="accountStore.userRoleIsImpLAndDManager"-->
                  <!--entity-type="dispatcher"-->
                  <!--class="entity-badge"-->
                  <!--/>-->
                  <!--<EntityBadge-->
                  <!--v-tooltip="'Pilot'"-->
                  <!--v-if="accountStore.userRoleIsImpTeamLeader"-->
                  <!--entity-type="pilot"-->
                  <!--class="entity-badge"-->
                  <!--/>-->
                  <!--</div>-->
                  {{ translationStore.t(`header.buttonInvitations.${accountStore.allRoles.ZLY_TEAM_LEADER.code}`) }}
                </BDropdownItem>
                <BDropdownItem
                  :active="$route.name === 'ConfigureEmailTemplate' && accountStore.userRoleIsZlyTeamLeader"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.ZLY_TEAM_LEADER, 'ConfigureEmailTemplate')"
                >
                  Your invitation message (email)
                </BDropdownItem>
                <!--                <BDropdownItem-->
                <!--                  v-if="globalStore.featureToggle.coPilot"-->
                <!--                  :active="$route.matched[0].name === 'CoPilot' && accountStore.userRoleIsZlyTeamLeader"-->
                <!--                  :link-class="'d-flex'"-->
                <!--                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.ZLY_TEAM_LEADER, 'CoPilot')"-->
                <!--                >-->
                <!--                  {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.ZLY_CO_TEAM_LEADER.id}`) }} management-->
                <!--                </BDropdownItem>-->
                <!--<BDropdownItem>-->
                <!--Item 3-->
                <!--</BDropdownItem>-->
              </ul>
            </li>
          </div>
          <!---->
          <!-- ZLY Team Member -->
          <!---->
          <div
            v-if="accountStore.userHasZlyTeamMemberRole"
            v-tooltip="isUserJourney ? tooltipItemDisabledIfUserJourney : ''"
          >
            <li
              :class="{ 'active-role': accountStore.userRoleIsZlyTeamMember, disabled: isUserJourney }"
              class="dropdown-item dropdown-submenu"
              @mouseenter="openSubmenu"
              @click="selectedRoleFromUserMenu(accountStore.allRoles.ZLY_TEAM_MEMBER.id)"
            >
              {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.ZLY_TEAM_MEMBER.id}`) }} <!-- Team Member-->
              <NotificationBadge
                :notification-type="'active-impulse'"
                :counter="globalStore.numberOfActiveImpulsesForEachRole[accountStore.allRoles.ZLY_TEAM_MEMBER.id] && globalStore.numberOfActiveImpulsesForEachRole[accountStore.allRoles.ZLY_TEAM_MEMBER.id].pendingImpulsesCount"
                class="badge-margin-left"
              />
              <!--<ul class="dropdown-menu left">-->
              <!--<BDropdownItem>-->
              <!--Item 1-->
              <!--</BDropdownItem>-->
              <!--<BDropdownItem>-->
              <!--Item 2-->
              <!--</BDropdownItem>-->
              <!--<BDropdownItem>-->
              <!--Item 3-->
              <!--</BDropdownItem>-->
              <!--</ul>-->
            </li>
          </div>
          <!---->
          <!-- JGO Partner -->
          <!---->
          <div
            v-if="accountStore.userHasJgoPartnerRole"
            v-tooltip="isUserJourney ? tooltipItemDisabledIfUserJourney : ''"
          >
            <li
              :class="{ 'active-role': accountStore.userRoleIsJgoPartner, disabled: isUserJourney }"
              class="dropdown-item dropdown-submenu"
              @mouseenter="openSubmenu"
              @click="selectedRoleFromUserMenu(accountStore.allRoles.JGO_PARTNER.id)"
            >
              {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.JGO_PARTNER.id}`) }} <!-- Partner -->
              <ul class="dropdown-menu left">
                <BDropdownItem
                  :active="$route.matched[0].name === 'ParticipantOrPartnerWelcome' && accountStore.userRoleIsJgoPartner"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.JGO_PARTNER, 'ParticipantOrPartnerWelcome')"
                >
                  Welcome
                </BDropdownItem>
                <BDropdownItem
                  v-if="globalStore.featureToggle.coPilot"
                  :active="$route.matched[0].name === 'PartnerManagement' && accountStore.userRoleIsJgoPartner"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.JGO_PARTNER, 'PartnerManagement')"
                >
                  Management
                </BDropdownItem>
              </ul>
            </li>
          </div>
          <!---->
          <!-- TLY Team Leader -->
          <!---->
          <div
            v-if="accountStore.userHasTlyTeamLeaderRole"
            v-tooltip="isUserJourney ? tooltipItemDisabledIfUserJourney : ''"
          >
            <li
              ref="tlyTeamLeaderOption"
              :class="{ 'active-role': accountStore.userRoleIsTlyTeamLeader, disabled: isUserJourney }"
              class="dropdown-item dropdown-submenu"
              @mouseenter="openSubmenu"
              @click="selectedRoleFromUserMenu(accountStore.allRoles.TLY_TEAM_LEADER.id)"
            >
              {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.TLY_TEAM_LEADER.id}`) }} <!-- Team Leader -->
              <ul class="dropdown-menu left">
                <!--                <BDropdownItem-->
                <!--                  :active="$route.matched[0].name === 'ParticipantOrPartnerWelcome' && accountStore.userRoleIsTlyTeamLeader"-->
                <!--                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.TLY_TEAM_LEADER, 'ParticipantOrPartnerWelcome')"-->
                <!--                >-->
                <!--                  Welcome-->
                <!--                </BDropdownItem>-->
                <BDropdownItem
                  ref="tlyTeamLeaderInvitationsOption"
                  :active="$route.matched[1] && $route.matched[1].name === 'TeamLeaderInvitations' && accountStore.userRoleIsTlyTeamLeader"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.TLY_TEAM_LEADER, 'TeamLeaderInvitations')"
                >
                  {{ translationStore.t('tlyTeamLeaderInvitations.teamAndInvitations') }}
                </BDropdownItem>
                <BDropdownItem
                  :active="$route.matched[0].name === 'TreasureChest' && accountStore.userRoleIsTlyTeamLeader"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.TLY_TEAM_LEADER, 'TreasureChest')"
                >
                  {{ translationStore.t('tlyTreasureChest.titleForHeader') }}
                </BDropdownItem>
                <BDropdownItem
                  :active="$route.matched[0].name === 'TreasureQuest' && accountStore.userRoleIsTlyTeamLeader"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.TLY_TEAM_LEADER, 'TreasureQuest')"
                >
                  {{ translationStore.t('tlyTreasureQuest.titleForHeader') }}
                </BDropdownItem>
              </ul>
            </li>
          </div>

          <!---->
          <!-- TLY Team Member (after being a candidate) -->
          <!---->
          <div
            v-if="accountStore.userHasTlyTeamMemberRole"
            v-tooltip="isUserJourney ? tooltipItemDisabledIfUserJourney : ''"
          >
            <li
              :class="{ 'active-role': accountStore.userRoleIsTlyTeamMember, disabled: isUserJourney }"
              class="dropdown-item dropdown-submenu"
              @mouseenter="openSubmenu"
              @click="selectedRoleFromUserMenu(accountStore.allRoles.TLY_TEAM_MEMBER.id)"
            >
              {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.TLY_TEAM_MEMBER.id}`) }} <!-- Team Member -->
              <ul class="dropdown-menu left">
                <!--                <BDropdownItem-->
                <!--                  :active="$route.matched[0].name === 'ParticipantOrPartnerWelcome' && accountStore.userRoleIsTlyTeamMember"-->
                <!--                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.TLY_TEAM_MEMBER, 'ParticipantOrPartnerWelcome')"-->
                <!--                >-->
                <!--                  Welcome-->
                <!--                </BDropdownItem>-->
                <BDropdownItem
                  :active="$route.matched[0].name === 'TreasureChest' && accountStore.userRoleIsTlyTeamMember"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.TLY_TEAM_MEMBER, 'TreasureChest')"
                >
                  {{ translationStore.t('tlyTreasureChest.titleForHeader') }}
                </BDropdownItem>
                <!--              <BDropdownItem-->
                <!--                v-if="globalStore.featureToggle.coPilot"-->
                <!--                :active="$route.matched[0].name === 'PartnerManagement' && accountStore.userRoleIsTlyCandidate"-->
                <!--                @click.stop="changeRoleAndGoTo(accountStore.allRoles.CANDIDATE, 'PartnerManagement')"-->
                <!--              >-->
                <!--                Management-->
                <!--              </BDropdownItem>-->
              </ul>
            </li>
          </div>

          <!---->
          <!-- TLY Candidate -->
          <!---->
          <div
            v-if="accountStore.userHasTlyCandidateRole"
            v-tooltip="isUserJourney ? tooltipItemDisabledIfUserJourney : ''"
          >
            <li
              :class="{ 'active-role': accountStore.userRoleIsTlyCandidate, disabled: isUserJourney }"
              class="dropdown-item dropdown-submenu"
              @mouseenter="openSubmenu"
              @click="selectedRoleFromUserMenu(accountStore.allRoles.TLY_CANDIDATE.id)"
            >
              {{ translationStore.t(`roleNameFromId.${accountStore.allRoles.TLY_CANDIDATE.id}`) }} <!-- Candidate -->
              <ul class="dropdown-menu left">
                <!--                <BDropdownItem-->
                <!--                  :active="$route.matched[0].name === 'ParticipantOrPartnerWelcome' && accountStore.userRoleIsTlyCandidate"-->
                <!--                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.TLY_CANDIDATE, 'ParticipantOrPartnerWelcome')"-->
                <!--                >-->
                <!--                  Welcome-->
                <!--                </BDropdownItem>-->
                <BDropdownItem
                  :active="$route.matched[0].name === 'TreasureChest' && accountStore.userRoleIsTlyCandidate"
                  @click.stop="changeRoleAndGoTo(accountStore.allRoles.TLY_CANDIDATE, 'TreasureChest')"
                >
                  {{ translationStore.t('tlyTreasureChest.titleForHeader') }}
                </BDropdownItem>
                <!--              <BDropdownItem-->
                <!--                v-if="globalStore.featureToggle.coPilot"-->
                <!--                :active="$route.matched[0].name === 'PartnerManagement' && accountStore.userRoleIsTlyCandidate"-->
                <!--                @click.stop="changeRoleAndGoTo(accountStore.allRoles.CANDIDATE, 'PartnerManagement')"-->
                <!--              >-->
                <!--                Management-->
                <!--              </BDropdownItem>-->
              </ul>
            </li>
          </div>

          <!---->
          <!-- Change role -->
          <!---->
          <!--<template v-if="accountStore.userRole && userRolesIdAndCode.length > 1">-->
          <!--<BDropdownDivider v-if="!isUserJourney && !accountStore.userRoleIsImpConsultant" />-->
          <!--<BDropdownItem-->
          <!--v-for="role in userRolesIdAndCode"-->
          <!--:key="`role-${role.code}`"-->
          <!--:disabled="accountStore.userRoleCode === role.code"-->
          <!--:active="accountStore.userRoleCode === role.code"-->
          <!--@click="selectedRoleFromUserMenu(role.id)"-->
          <!--&gt;-->
          <!--{{ roleName(role.code) }}-->
          <!--</BDropdownItem>-->
          <!--</template>-->
          <!---->
          <!---->
          <!---->

          <BDropdownDivider v-if="!accountStore.userRoleIsJgoParticipant" />

          <BDropdownText
            @click.stop
          >
            <div>
              <div class="menu-about-title">
                About
              </div>
              <div class="menu-about-text">
                v{{ globalStore.currentVersion }} (Web), v{{ globalStore.currentApiVersion }} (API),
                <br>
                {{ translationStore.companyTranslation }}
              </div>
            </div>
          </BDropdownText>

          <BDropdownDivider />

          <template
            v-if="!authStore.isAuthenticated && isUserJourney && accountStore.invitationInfo"
          >
            <BDropdownItem @click="$router.push('/')">
              Exit GetReady Program
            </BDropdownItem>
          </template>
          <template v-else-if="authStore.isImpersonating">
            <BDropdownItem @click="authStore.exitImpersonate">
              Exit Impersonation
            </BDropdownItem>
          </template>
          <template v-else>
            <BDropdownItem :disabled="busy.signingOut">
              <div @click.stop="signOut">
                <div v-if="busy.signingOut">
                  <div class="spinner-container-inline justify-content-start">
                    {{ translationStore.t('header.buttonSigningOut') }}
                    <AppSpinner
                      size="small"
                      class="spinner ml-gap-0_38x"
                      :delay="0"
                    />
                  </div>
                </div>
                <div v-else>
                  {{ translationStore.t('header.buttonSignOut') }}
                </div>
              </div>
            </BDropdownItem>
          </template>
        </BNavItemDropdown>
      </div>

      <!--      <div-->
      <!--        v-if="$slots['text-right-of-header']"-->
      <!--        class="text-right-of-header"-->
      <!--      >-->
      <!--        <slot name="text-right-of-header" />-->
      <!--      </div>-->
    </BNavbarNav>

    <!--</BCollapse>-->

    <!-- Center items -->
    <!--    <BNavbarNav class="nav-center">-->
    <!--      <div-->
    <!--        v-if="!hideText"-->
    <!--        class="selected-person-nickname-and-current-route"-->
    <!--      >-->
    <!--        <span v-if="showSelectedPerson">-->
    <!--          {{ selectedPersonNickname }}-->
    <!--        </span>-->
    <!--        <template v-if="accountStore.userRole && translationStore.te(`header.pageName.${$route.matched[0].name}.${accountStore.userRoleCode}`)">-->
    <!--          <span-->
    <!--            v-if="showSelectedPerson"-->
    <!--            class="space-around-dot"-->
    <!--          >-->
    <!--            ●-->
    <!--          </span>-->
    <!--          <span>-->
    <!--            {{ translationStore.t(`header.pageName.${$route.matched[0].name}.${accountStore.userRoleCode}`) }}-->
    <!--          </span>-->
    <!--        </template>-->
    <!--        <template v-else-if="translationStore.te(`header.pageName.${$route.matched[0].name}`)">-->
    <!--          <span-->
    <!--            v-if="showSelectedPerson"-->
    <!--            class="space-around-dot"-->
    <!--          >-->
    <!--            ●-->
    <!--          </span>-->
    <!--          <span>-->
    <!--            {{ translationStore.t(`header.pageName.${$route.matched[0].name}`) }}-->
    <!--          </span>-->
    <!--        </template>-->
    <!--        <template v-if="globalStore.isAppTly && showJobTitle">-->
    <!--          {{ globalStore.tlyCurrentJob && globalStore.tlyCurrentJob.title }}-->
    <!--        </template>-->
    <!--      </div>-->
    <!--      <div class="text-center-of-header">-->
    <!--        <slot name="text-center-of-header" />-->
    <!--      </div>-->
    <!--    </BNavbarNav>-->
  </BNavbar>
</template>

<script
  setup
  lang="ts"
>
import { computed, inject, ref, watch, watchEffect } from 'vue';
import { isNavigationFailure, NavigationFailureType, useRoute, useRouter } from 'vue-router';
import { useEventBus } from '@vueuse/core';
import { BDropdownItem } from 'bootstrap-vue-next'; // explicit import needed, to be used with "typeof BDropdownItem"
import { useAuthStore } from '@/stores/auth';
import { type CoTeamLeader, useAccountStore, type UserRole } from '@/stores/account';
import { useGlobalStore } from '@/stores/global';
import { useTranslationStore } from '@/stores/translation';
import { useTreasureQuestStore } from '@/stores/treasureQuest';
import { useUtils } from '@/composables/useUtils';

import AppLogo from '@/components/misc/AppLogo.vue';
import AppIcon from '@/components/misc/AppIcon.vue';
import AppSpinner from '@/components/misc/AppSpinner.vue';
// import EntityBadge from '@/components/misc/EntityBadge.vue';
import NotificationBadge from '@/components/misc/NotificationBadge.vue';
import AviationSpellingGeneratorModal from '@/components/misc/AviationSpellingGeneratorModal.vue';
import AppAvatar from '@/components/misc/avatar/AppAvatar.vue';

import LanguageSelector from '@/components/utils/LanguageSelector.vue';

import UserJourneyPhaseAndStepIndicators from '@/components/onboarding/UserJourneyPhaseAndStepIndicators.vue';

const props = defineProps({
  hideHeader: {
    type: Boolean,
    default: false,
  },
  hideButtons: {
    type: Boolean,
    default: false,
  },
  hideAvatar: {
    type: Boolean,
    default: false,
  },
  hideText: {
    type: Boolean,
    default: false,
  },
  isUserJourney: {
    type: Boolean,
    default: false,
  },
  isUserJourneyTalentManual: {
    type: Boolean,
    default: false,
  },
  isInvitationConfirmationPage: {
    type: Boolean,
    default: false,
  },
  userJourneyPage: {
    type: Number,
    default: 0,
  },
});

const headerEventBus = useEventBus('header');
const { on } = useEventBus('entity-block');

const $router = useRouter();
const $route = useRoute();
// const $http: undefined | AxiosStatic = inject('$http');
const $log: any = inject('$log');

const accountStore = useAccountStore();
const authStore = useAuthStore();
const globalStore = useGlobalStore();
const translationStore = useTranslationStore();
const treasureQuestStore = useTreasureQuestStore();
const utils = useUtils();

const busy = ref({
  signingOut: false,
});
// const featureToggle = ref({
//   // searchIsEnabled: false,
// });
// const searchHasFocus = ref(false);
const sosIsOn = ref(false);
const alertIsOn = ref(false);
const isMainMenuShown = ref(false);

// Template refs
const aviationSpellingGeneratorModalComponent = ref<InstanceType<typeof AviationSpellingGeneratorModal> | null>(null);
const tlyTeamLeaderOption = ref<HTMLElement | null>(null);
const tlyTeamLeaderInvitationsOption = ref<InstanceType<typeof BDropdownItem> | null>(null);

const isRouteSignInOrSignedOut = computed(() => $route.name && ['SignIn', 'SignedOut'].includes($route.name.toString()));

// eslint-disable-next-line arrow-body-style
const showDefaultButtons = computed(() => {
  // Default buttons if the page is not one of this:
  return !(
    props.hideButtons
    || props.isUserJourney
    || props.isUserJourneyTalentManual
    || isRouteSignInOrSignedOut.value
    || props.isInvitationConfirmationPage
  );
});

// // eslint-disable-next-line arrow-body-style
// const userRolesIdAndCode = computed(() => {
//   // userRoles has IDs
//   return accountStore.userRoles
//     .filter((ur) => globalStore.featureToggle.coPilot || ur.id !== 6) // hide Co-Team Leader if feature disabled
//     .map((ur) => ({
//       ...ur,
//       code: Object.values(accountStore.allRoles).find((role) => role.id === ur.id).code,
//     }));
// });

const userProfileWithActiveRowsForAvatar = computed(() => {
  if (!accountStore.userProfile) {
    return null;
  }
  // return globalStore.people.find((p) => p.id === accountStore.userId) || accountStore.userProfile;
  return accountStore.userProfile?.entity_block;
});

// // eslint-disable-next-line arrow-body-style
// const showSelectedPerson = computed(() => {
//   // Only on Impulse Area, Talent Manual
//   // Do not show on profile/account, invitations, etc.
//   return $route.matched[0]
//     && ($route.matched[0].name === 'ImpulseArea'
//       || $route.matched[0].name === 'TalentManual');
// });

// const showJobTitle = computed(() => {
//   return $route.matched[0]
//     && ($route.matched[0].name === 'JobDigs'
//       || $route.matched[0].name === 'JobDigsReview'
//       || $route.matched[0].name === 'JobSoftFactors');
// });

// const selectedPersonNickname = computed(() => {
//   const temporaryEntity = globalStore.temporarySelectedEntity;
//   if (temporaryEntity) {
//     return temporaryEntity.nickname;
//   }
//   if (globalStore.selectedPerson) {
//     return globalStore.selectedPerson.nickname;
//   }
//   return accountStore.userProfile?.nickname;
// });

// const tooltipForEntityBadgeCurrentRole = computed(() => {
//   if (accountStore.userRoleIsAdministrator) {
//     return 'Administrator';
//   }
//   if (accountStore.userRoleIsImpConsultant) {
//     return 'Consultant';
//   }
//   if (accountStore.userRoleIsImpLAndDManager) {
//     return 'Dispatcher';
//   }
//   if (accountStore.userRoleIsImpTeamLeader) {
//     return 'Pilot';
//   }
//   if (accountStore.userRoleIsImpCoTeamLeader) {
//     return 'Co-Pilot';
//   }
//   if (accountStore.userRoleIsImpTeamMember) {
//     return 'Crew Member';
//   }
//   return '?';
// });

// const entityTypeForBadgeCurrentRole = computed(() => {
//   if (accountStore.userRoleIsAdministrator) {
//     return 'dispatcher';
//   }
//   if (accountStore.userRoleIsImpConsultant) {
//     return 'dispatcher';
//   }
//   if (accountStore.userRoleIsImpLAndDManager) {
//     return 'dispatcher';
//   }
//   if (accountStore.userRoleIsImpTeamLeader) {
//     return 'pilot';
//   }
//   if (accountStore.userRoleIsImpCoTeamLeader) {
//     return 'co-pilot';
//   }
//   if (accountStore.userRoleIsImpTeamMember) {
//     return 'crew-member';
//   }
//   return '?';
// });

const totalNumberOfOpenImpulses = computed(() => Object.values(globalStore.numberOfActiveImpulsesForEachRole)
  .reduce((acc, cur) => acc + cur.pendingImpulsesCount, 0));

const tooltipItemDisabledIfUserJourney = computed(() => translationStore.t('header.tooltipItemDisabledIfUserJourney'));

// eslint-disable-next-line arrow-body-style
const treasureQuestTooltip = computed(() => {
  // if (!accountStore.userRoleIsTlyTeamLeader) {
  return translationStore.t('header.tooltipTlyTreasureQuest');
  // }
  // return `${translationStore.t('header.tooltipTlyTreasureQuest')}<br>Create a Job position and find your PerfectCandidate`;
});

watch(() => globalStore.receivedHelpRequestsCount, () => {
  sosIsOn.value = !!globalStore.receivedHelpRequestsCount;
});

watch(() => globalStore.receivedNuggetsCount, () => {
  alertIsOn.value = !!globalStore.receivedNuggetsCount;
});

async function animateMenuToTeamLeaderInvitations() {
  isMainMenuShown.value = true;
  await utils.wait(500);

  const event = new MouseEvent('mouseenter', {
    view: window,
    bubbles: true,
    cancelable: false,
  });
  if (tlyTeamLeaderOption.value) { // <li>
    tlyTeamLeaderOption.value.dispatchEvent(event); // will trigger the openSubmenu method
  }

  await utils.wait(500);
  // Blink the invitations option
  if (tlyTeamLeaderInvitationsOption.value) { // <BDropdownItem>
    const className = 'highlight-blink';
    tlyTeamLeaderInvitationsOption.value.$el.classList.add(className);
    await utils.wait(2000);
    tlyTeamLeaderInvitationsOption.value.$el.classList.remove(className);
    // Must click the <a> inside the <li>:
    tlyTeamLeaderInvitationsOption.value.$el.children[0].click(); // navigates to the Invitations page
  }
}

watchEffect(() => {
  if (globalStore.events.headerAnimateMenuToTeamLeaderInvitations) {
    globalStore.setEventHeaderAnimateMenuToTeamLeaderInvitations(false); // reset
    animateMenuToTeamLeaderInvitations();
  }
});

async function signOut() {
  /** own method instead of directly in mounted() so it can be tested */
  try {
    busy.value.signingOut = true;
    // await utils.wait(3000); // DEBUG
    await authStore.signOut(); // will redirect to SignIn
  } catch (error) {
    $log.error(error);
  } finally {
    busy.value.signingOut = false;
    isMainMenuShown.value = false; // close the menu
  }
}

// function redirectToProfile() {
//   if (accountStore.userHasTalentManual) {
//     // $log.debug('Selecting user profile as the selected person');
//     // $log.debug('Redirecting to the profile page');
//     globalStore.clearTemporarySelectedEntity();
//     $router.push({ name: 'TalentManual' });
//   }
// }

async function goToHome() {
  if (props.isUserJourney) {
    return;
  }
  const navigationResult = await $router.push('/');
  if (navigationResult) {
    // navigation prevented
    if (isNavigationFailure(navigationResult, NavigationFailureType.duplicated)) {
      // Was already at this route. Refresh the page?
      window.location.reload();
    } else {
      $log.error(navigationResult);
    }
  } else {
    // navigation succeeded (this includes the case of a redirection)
  }
}

function goToOwnTalentManual() {
  // Reset temporary entity
  globalStore.clearTemporarySelectedEntity();
  $router.push({ name: 'TalentManual' });
}

// function roleName(roleCode) {
//   const active = accountStore.userRoleCode === roleCode;
//   switch (roleCode) {
//     case accountStore.allRoles.ADMIN.code:
//       return active ? translationStore.t('header.atUserRoleAdmin') : translationStore.t('header.changeToUserRoleAdmin');
//     case accountStore.allRoles.DISPATCHER.code:
//       return active ? translationStore.t('header.atUserRoleDispatcher') : translationStore.t('header.changeToUserRoleDispatcher');
//     case accountStore.allRoles.PILOT.code:
//       return active ? translationStore.t('header.atUserRolePilot') : translationStore.t('header.changeToUserRolePilot');
//     case accountStore.allRoles.CREW_MEMBER.code:
//       return active ? translationStore.t('header.atUserRoleCrewMember') : translationStore.t('header.changeToUserRoleCrewMember');
//     case accountStore.allRoles.CONSULTANT.code:
//       return active ? translationStore.t('header.atUserRoleConsultant') : translationStore.t('header.changeToUserRoleConsultant');
//     case accountStore.allRoles.CO_PILOT.code:
//       return active ? translationStore.t('header.atUserRoleCoPilot') : translationStore.t('header.changeToUserRoleCoPilot');
//     default:
//       return '(Unknown role ID)';
//   }
// }

function clickRefresh() {
  window.location.reload();
}

// function clickWhatsNew() {
//   $router.push({ name: 'ReleaseNotes' });
//   globalStore.resetShowWhatsNew();
// }

async function changeRoleAndGoTo(role: UserRole, routeName: string) {
  if (accountStore.userRoleId === role.id) {
    // Already in this role, just change the page
    await $router.push({ name: routeName });
    return;
  }
  try {
    await accountStore.changeRoleIDServerSide(role.id);
    await $router.push({ name: routeName });
  } catch (error) {
    $log.error(error);
  } finally {
    //
  }
}

async function selectCoPilotPilotAndChangeRole(role: UserRole, pilot: CoTeamLeader) {
  try {
    await changeRoleAndGoTo(role, 'Home');
    accountStore.selectCoPilotPilot(pilot); // will call loadPeopleAndGroups
  } catch (error) {
    $log.error(error);
    //
  }
}

async function selectedRoleFromUserMenu(roleId: number) {
  try {
    globalStore.increaseGlobalLoadingCounter();
    await accountStore.changeRoleIDServerSide(roleId);
    // Redirect to role home if the user can't access current route
    if ($route.meta
      && $route.meta.allowedRoleIDs
      && Array.isArray($route.meta.allowedRoleIDs)
      && $route.meta.allowedRoleIDs.includes(roleId)) {
      // this new role can stay in this route, do not redirect.
      if (roleId === accountStore.allRoles.IMP_CO_TEAM_LEADER.id
        && accountStore.coPilotPilots
        && accountStore.coPilotPilots.length) {
        // Changing/selecting Co-Team Leader: select their first Pilot
        await selectCoPilotPilotAndChangeRole(accountStore.allRoles.IMP_CO_TEAM_LEADER, accountStore.coPilotPilots[0]);
      }
    } else {
      // this role has no access to this route, go home.
      await $router.push('/');
    }
  } catch (error) {
    $log.error(error);
  } finally {
    globalStore.decreaseGlobalLoadingCounter();
  }
}

function mainMenuShown() {
  $log.debug('mainMenuShown');
  if (accountStore.userHasImpCoTeamLeaderRole) {
    accountStore.fetchCoPilotPilots();
  }
}

function closeSubmenus() {
  Array.from(document.getElementsByClassName('dropdown-submenu'))
    .forEach((submenu) => {
      Array.from(submenu.getElementsByClassName('dropdown-menu'))
        .forEach((menu: Element) => {
          menu.classList.remove('show');
        });
    });
}

function mainMenuHidden() {
  $log.debug('mainMenuHidden');
  closeSubmenus();
}

function openSubmenu(event: MouseEvent) {
  closeSubmenus();
  // Open this one
  if (event.target && 'getElementsByClassName' in event.target) {
    const submenu = (event.target as Element).getElementsByClassName('dropdown-menu')[0];
    if (submenu) {
      submenu.classList.add('show');
    }
  }
}

function openAviationSpellingGeneratorModal() {
  if (aviationSpellingGeneratorModalComponent.value) {
    aviationSpellingGeneratorModalComponent.value.showModal();
  }
}

function onAvatarImageError(error: Event) {
  $log.warn('Header: onAvatarImageError, fetching new data');
  globalStore.errorFetchingImage(error); // global store
}

on((event, payload) => {
  if (event === 'change-to-role') {
    selectedRoleFromUserMenu(payload);
  }
});
</script>

<style
  lang="scss"
  scoped
>
.navbar {
  height: $header-height;
  padding: 0 2.55rem; // 2023-04-21 Bootstrap 5 now has a container-fluid at the navbar, with a 0.75rem left and right padding (--bs-gutter-x of 1.5rem * 0.5)
  background-color: $header-bg-color;
  box-shadow: 0 -4px 14px 0 var(--dark);

  .navbar-nav {
    height: 100%;

    //flex-direction: row !important; // prevent changing to column on small screens, for now
    align-self: center; // 2023-04-24 added for Vue 3 BootstrapVueNext (using Bootstrap 5)

    a {
      outline: none;
    }

    &.icons-left {
      position: absolute;
      top: 1px; // de-centre the icons a little bit
      left: calc(31.96% - #{2 * $gap-3x} + #{$gap-1x} + 3px);

      .app-jgo &,
      .app-tly & {
        position: relative;
        left: 0;
      }

      .theme-v3e & {
        position: unset;
        top: unset;
        left: unset;
        margin-top: 1px; // de-centre the icons a little bit
      }

      /*background-color: rebeccapurple;*/
    }

    &.icons-right {
      position: relative;
      top: 1px; // de-centre the icons a little bit
    }

    &.icons-right-first-set {
      position: relative;
      top: 1px; // de-centre the icons a little bit
      //margin-right: $gap-0_38x;
      margin-right: calc(.5 * #{$header-height} - #{$gap-0_38x} / 2); // "simulate an icon"
    }

    &.nav-center {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;

      font-size: $font-18px;
      font-weight: bold;

      .theme-v3e & {
        color: var(--text-color-1);
      }

      .text-center-of-header {
        display: flex;
        align-items: center;
        /*background-color: orange;*/
        font-size: $font-20px;
      }
    }
  }

  .navbar-collapse {
    height: 100%;
  }

  .theme-v3e & {
    $shadow-color-dark-2: rgba(#C8D2E6, 0.5);
    box-shadow: 0 0.22rem 0.15rem $shadow-color-dark-2; // 2020-09-23

    :deep(.dropdown-menu) {
      background-color: white;

      .selected {
        // .selected is used on <li> of language selector (while dropdown-item.active is used on other dropdowns)
        .dropdown-item {
          color: white;
        }
      }

      li {
        &.highlight-blink {
          animation: blink-bg .5s alternate infinite ease-in-out;

          @keyframes blink-bg {
            0% {
              background-color: white;
            }

            100% {
              background-color: var(--accent-color);
            }
          }

          .dropdown-item {
            animation: blink-fg .5s alternate infinite ease-in-out;

            @keyframes blink-fg {
              0% {
                color: var(--text-color-1);
              }

              100% {
                color: white;
              }
            }
          }
        }
      }

      .dropdown-item {
        color: var(--text-color-1);

        &.text-secondary {
          color: var(--text-color-1) !important;

          &.disabled {
            color: var(--text-color-2) !important;
          }
        }

        //&:hover {
        //  background-color: var(--accent-color);
        //}

        &.router-link-active,
        &.router-link-active:hover,
        &.selected,
        &:active,
        &.active {
          background-color: var(--accent-color);
          color: white !important;
        }
      }
    }
  }

  :deep(.text-muted),
  :deep(.text-body-secondary) {
    // Bootstrap 5 default color for text-muted is "color: #6c757d !important;"
    // 2023-08-11 bootstrap dropdown class "text-muted" is now "text-body-secondary"
    color: $light-primary !important;
    cursor: default; // no text cursor

    .theme-v3e & {
      color: var(--text-color-1) !important;
    }
  }
}

.is-hidden {
  visibility: hidden;
}

.row {
  height: 100%;
  align-items: center;
}

//.navbar-brand {
//  position: relative;
//  display: flex;
//  align-items: center;
//  height: 62%;
//  /*border: 1px solid red;*/
//  font-weight: 600;
//  padding: 0;
//  margin-right: $gap-3x;
//  color: #272C32;
//}

.navbar-brand {
  height: calc(#{$header-height} * .62);
  padding: 0;
  margin-right: $gap-3x;
}

.nav-item {
  font-weight: bold;
  display: flex;
  align-items: center;

  height: fit-content; // so that the tooltip is near the icon
  align-self: center;

  :deep(.nav-link) {
    display: flex;
    align-items: center;
    /*align-self: center;*/
    justify-content: center;
    height: 100%;
    padding: 0 calc(#{$gap-0_38x} / 3) !important;

    .theme-v3e & {
      //padding: 0 !important;
      padding: 0 calc(#{$gap-0_38x} / 2) !important;
    }

    // &.active {
    //   // box-shadow: inset 0 -10px 0 -6px $cerulean;
    // }

    &.disabled {
      .circle-background {
        /*fill: $battleship-grey;*/
        fill: lighten($dark, 10%);
      }

      .circle-ring {
        /*stroke: $blush;*/
        stroke: darken($light, 10%);
      }

      .inside-icon {
        /*fill: $battleship-grey-two !important;*/
        fill: darken($light, 10%);
      }
    }
  }

  .app-icon,
  .language-selector,
  :deep(.app-icon) {
    $size: calc(.5 * #{$header-height});
    width: $size;
    height: $size;
  }
}

/* .tmp-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-image: radial-gradient(circle at 50% 50%, #312f2f, #202020 64%, #fff 89%, #fff);
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 25px !important;
    height: 25px !important;
  }
} */

/* .btn-image {
  border: none;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  box-shadow: none;
  &.btn-impulseArea {
    background: url("./assets/header_button_1.png") !important;
    background-size: 100% !important;
  }
  &.btn-sixPack {
    background: url("./assets/header_button_2.png") !important;
    background-size: 100% !important;
  }
  &.btn-logbook {
    background: url("./assets/header_button_3.png") !important;
    background-size: 100% !important;
  }
} */

.avatar-and-name-dropdown {
  display: flex;
  align-items: center;
  margin-left: $gap-1x;

  .theme-v3e & {
    margin-left: calc(.5 * #{$header-height}); // "simulate an icon"
  }

  .header-name-dropdown {
    :deep(.dropdown-toggle) {
      overflow: visible; // do not cut the round notification badge
      background-color: unset;
    }

    :deep(.nav-link) {
      padding: 0 !important;
      outline: none; // fix square around the avatar when focused
    }

    :deep(.dropdown-menu) {
      display: block; // default is none, and will not animate
      visibility: hidden;
      opacity: 0;
      transition: opacity 200ms ease-out;

      &.show {
        visibility: visible;
        opacity: 1;
        overflow: visible !important; // fix 2023-11-23 BootstrapVueNext v0.15.5 "overflow auto and sizing middleware to limit the size to visible viewport" hidding the sub-menus
      }
    }
  }
}

.spinner-container-inline {
  .spinner {
    margin-right: 15px;
    margin-top: 2px;
  }
}

.router-link-active, .active {
  color: var(--text-color-1);
  font-weight: bold;
  margin-bottom: 1px solid var(--water-color);
}

.selected-person-nickname-and-current-route {
  cursor: default;
  display: flex;
  align-items: center;
}

.text-right-of-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: $font-12px;
  color: var(--accent-color);
  text-align: right;
  line-height: 1.2;
  margin-left: $gap-1x;
}

.language-selector-wrapper, .dropdown-icon-wrapper-outer {
  display: flex;
  align-items: center;
  /*align-self: center;*/
  //padding: 0 calc(0.695vw / 3); // same as nav-link buttons
}

.new-version-or-updated-message-wrapper {
  @extend .text-right-of-header;
  padding: $gap-0_38x $gap-1x;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 5%); // white background, in case there is text behind
  z-index: 1;

  --border-radius: #{$border-radius-3px};
  --color: var(--accent-color);
  --color-light: var(--accent-color);

  .new-version, .whats-new {
    height: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    cursor: default;

    .icon-placeholder {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: var(--border-radius) 0 0 var(--border-radius); // left corners only

      .theme-v3e & {
        :deep(.inside-icon) path {
          fill: white; // because the default is var(--text-color-1)
        }
      }
    }

    .content {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 6px 0 $gap-0_38x;
      border-radius: 0 var(--border-radius) var(--border-radius) 0; // right corners only
    }
  }

  .new-version {
    /*color: #E90054;*/
    color: var(--color);

    .icon-placeholder {
      /*border: 1px solid $blush;*/
      /*background-color: $blush;*/
      border: 1px solid var(--color-light);
      background-color: var(--color-light);
    }

    .content {
      /*border: 1px solid $blush;*/
      border: 1px solid var(--color-light);
      border-left: none;
    }
  }

  .whats-new {
    color: var(--color);

    .icon-placeholder {
      border: 1px solid var(--color-light);
      background-color: var(--color-light);
    }

    .content {
      border: 1px solid var(--color-light);
      border-left: none;
    }
  }

  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 $gap-0_38x;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;

    .close-x {
      height: 50%;
    }
  }
}

.demo-mode-text {
  @extend .text-right-of-header;
  margin-right: $gap-2x;
  text-transform: uppercase;
  font-weight: bold;
  cursor: default;
}

.menu-nickname {
  font-weight: bold;
  font-size: $font-14px;
}

.menu-username {
  font-weight: normal;
  font-size: $font-10px;
}

.menu-about-title {
  font-weight: bold;
  //font-size: $font-10px;
}

.menu-about-text {
  font-weight: normal;
  font-size: $font-10px;
}

//

.badge-container {
  $size: $gap-0_62x;
  width: $size;
  height: $size;
  margin-right: calc(#{$gap-0_38x} / 2);

  &.at-icon-dropdown {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .entity-badge {
    position: relative;
    width: 100%;
    height: 100%;

    :deep(svg) {
      position: absolute;
    }
  }
}

.dropdown-icon-wrapper {
  position: relative;
  cursor: pointer;
  width: calc(#{$header-height} / 2);
  height: calc(#{$header-height} / 2);

  .btn-group {
    z-index: 2;
    width: 100%;
    height: 100%;

    :deep(button) {
      // Hide the button
      width: 100%;
      overflow: hidden;
      opacity: 0;
      z-index: 1;
    }
  }
}

/**/
/* Dropdown sub-menu */
/**/
.dropdown-submenu {
  position: relative;
  cursor: pointer;

  .dropdown-menu {
    top: 0;
    left: 100%;
    margin-top: -1px;

    &.left {
      transform: translateX(-100%);
      left: 1px;
    }
  }
}

/*.demo-sos-switch-hotspot {*/
/*  background-color: red;*/
/*  position: absolute;*/
/*  top: 0;*/
/*  right: 0;*/
/*  width: 1vw;*/
/*  height: 1vw;*/
/*  cursor: pointer;*/
/*}*/

/*.demo-alert-switch-hotspot {*/
/*  !*background-color: red;*!*/
/*  position: absolute;*/
/*  top: 0;*/
/*  right: 0;*/
/*  width: 1vw;*/
/*  height: 1vw;*/
/*  cursor: pointer;*/
/*}*/

/*.avatar-help-overlay-old {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $light;
  color: var(--fire-color);
  border: 3px solid var(--fire-color);
  border-radius: 50%;
  font-size: $font-10px;
  font-weight: bold;
  box-shadow: 0 0 30px 0 var(--fire-color);

  animation: blink 2.5s infinite ease-out;

  @keyframes blink {
    0% {
      opacity: 0;
    }

    20%, 35% {
      opacity: 1;
    }

    65%, 100% {
      opacity: 0;
    }
  }
}*/

.avatar-help-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img {
    position: absolute;
    top: 2%;
    left: 2%;
    width: 96%;
    height: 96%;
  }

  animation: blink 2.5s infinite ease-out;

  @keyframes blink {
    0% {
      opacity: 0;
    }

    20%, 35% {
      opacity: 1;
    }

    65%, 100% {
      opacity: 0;
    }
  }
}

.avatar-notification-overlay {
  position: absolute;
  bottom: -5px;
  right: 0;
  z-index: 2;

  .badge {
    --bs-badge-padding-x: 0.3rem;
    --bs-badge-padding-y: 0.125rem;
  }
}

.alert-badge1 {
  &._ngcontent-jmc-29 {
    box-sizing: border-box;
    flex-shrink: 0;
    flex-grow: 0;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij4KPHBhdGggZmlsbD0iI0M1MzkyOSIgZD0iTTEyLDJDNi41LDIsMiw2LjUsMiwxMnM0LjUsMTAsMTAsMTBzMTAtNC41LDEwLTEwUzE3LjUsMiwxMiwyeiBNMTMsMTdoLTJ2LTJoMlYxN3ogTTEzLDEzaC0yVjdoMlYxM3oiLz4KPHJlY3QgeD0iMTEiIHk9IjciIGZpbGw9IiNGRkZGRkYiIHdpZHRoPSIyIiBoZWlnaHQ9IjYiLz4KPHJlY3QgeD0iMTEiIHk9IjE1IiBmaWxsPSIjRkZGRkZGIiB3aWR0aD0iMiIgaGVpZ2h0PSIyIi8+Cjwvc3ZnPgo=");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    height: 48px;
    padding: 12px;
    width: 48px;
  }
}

.badge1 {
  z-index: 1;

  &._ngcontent-jmc-29 {
    position: absolute;
    /*top: -8px;*/
    /*left: 11px;*/
    right: -0.9vw;
    bottom: -0.9vw;
  }
}

.learning-nugget-icon-notification-badge {
  position: absolute;
  /*bottom: 0.3vw;*/
  /*right: 0.1vw;*/
  bottom: -0.7vw;
  right: 0;

  .theme-v3e & {
    //bottom: -0.5rem; // with inline-block badge
    bottom: -0.3rem; // with block badge
  }
}

.treasure-quest-icon-notification-badge {
  .badge {
    display: block;
  }

  position: absolute;
  /*bottom: 0.3vw;*/
  /*right: 0.1vw;*/
  //bottom: -0.5rem; // with inline-block badge
  bottom: -0.3rem; // with block badge
  right: 0;
}

.badge-margin-left {
  margin-left: calc(#{$gap-0_38x} / 2);
}

.space-around-dot {
  margin: 0 calc(#{$gap-0_38x} / 2);
}

.clickable-but-hidden {
  opacity: 0;
}

.exit-impersonation {
  display: flex;
  align-items: center;
  background-color: var(--accent-color);
  color: white;
  margin-right: $gap-2x;
  padding: 0 $gap-0_62x;
  border-radius: $border-radius-6px;
  font-weight: bold;
  font-size: $font-10px;
  cursor: pointer;
}
</style>
